import React, { Component } from "react";
import Translate from "../../../../i18n/translate";
import StyledChipInterface from "../../../../componentsUI/styledComponents/styledChipInterface";
import { Grid2, Avatar, Tooltip, Typography } from "@mui/material";
import { CiFolderOn } from 'react-icons/ci'; // icon arquivo
import { PiFilePlusThin } from "react-icons/pi";// icon soap
import { CiServer } from "react-icons/ci"; // icon rest
import { SiSap } from "react-icons/si"; // icon sap 
import { PiFolderSimpleMinusLight } from "react-icons/pi"; // icon ftp
import { PiFolderSimpleLockThin } from "react-icons/pi"; // icon sftp
import { TbOvalVertical } from "react-icons/tb"; // icon oracle
import { DiMysql } from "react-icons/di"; // icon mysql
import { SiMicrosoftsqlserver } from "react-icons/si"; // icon sqlserver
import { SiPostgresql } from "react-icons/si"; // icon postgres
import { IoIosGitMerge } from "react-icons/io"; // icon merge
import { SiIbm } from "react-icons/si"; // icon ibm
import { CiCloudOn } from "react-icons/ci"; // icon google cloud
import { PiBankThin } from "react-icons/pi"; // icon bank
import { PiFileLockThin } from "react-icons/pi"; // icon criptografia
import { PiDatabaseThin } from "react-icons/pi"; // icon big query
import { TbSdk } from "react-icons/tb"; // icon SKD

class EventsItems extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
    
    // Mapa de icones
    this.iconMapping = {
      "file": < CiFolderOn />,
      "soap": <PiFilePlusThin />,
      "rest": <CiServer />,
      "sapr3": < SiSap />,
      "ftp": <PiFolderSimpleMinusLight />,
      "sftp": <PiFolderSimpleLockThin />,
      "oracle": <TbOvalVertical />,
      "mysql": <DiMysql />,
      "mssql": <SiMicrosoftsqlserver />,
      "postgres": <SiPostgresql />,
      "merge": <IoIosGitMerge />,
      "informix": < SiIbm />,
      "gcloud": <CiCloudOn />,
      "bank": <PiBankThin />,
      "cryptography": <PiFileLockThin />,
      "bigquery": <PiDatabaseThin />,
      "sdk": <TbSdk/>,
    };
  }

  componentWillReceiveProps = ({ disableEventComponent, title, events }) => {
    this.setState({ title, events, disableEventComponent });
  };

  onDrag = (eventInfo, event) => event.dataTransfer.setData("eventInfo", JSON.stringify(eventInfo));

  render() {
    const { title, events, position, disableEventComponent } = this.state;

    if (disableEventComponent) return null;

    return (
      <Grid2 style={title === "modifiers" || title === "banks" ? { marginTop: "10px" } : {}}>
        <Grid2 size={2}>
          <Typography gutterBottom style={{ fontSize: "1rem", color: "#6F8296" }}>
            {Translate(title)}
          </Typography>
        </Grid2>
        <Grid2 size={10}>
          <Grid2
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{
              backgroundColor: "#EEEEEE",
              border: "0px solid #b8c6d6",
              borderRadius: "20px",
              padding: "7px",
            }}>
            {events.map((event) => (
              <Grid2 key={`event-item-${event.description}`}>
                <Tooltip placement={position === "side" ? "top" : "right"} title={<h3>{event.label}</h3>}>
                  <StyledChipInterface
                    sx={{ width: "99%" }}
                    avatar={
                      title === "modifiers" || title === "banks" ? (
                        title === "modifiers" ? (
                          <Avatar
                            alt={event.label}
                            src={`/static/images/events/${event.description}.png`}
                            style={{ borderRadius: "0", marginLeft: "5px", marginRight: "-4px" }}
                          />
                        ) : (
                          <Avatar
                            alt={event.label}
                            src={`/static/images/banksLogos/${event.description}.png`}
                            style={{ borderRadius: "0px", marginLeft: "5px", marginRight: "-4px" }}
                          />
                        )
                      ) : (

                        this.iconMapping[event.description] || (
                          <Avatar
                            alt={event.label}
                            src={`/static/images/events/${event.description}.png`}
                            style={{ borderRadius: "0", marginLeft: "18px", marginRight: "-4px" }}
                          />
                        )
                      )
                    }
                    label={event.label}
                    draggable={true}
                    onDragStart={this.onDrag.bind(this, {
                      codBank: event.codBank,
                      codConnectionType: event.codConnectionType,
                      codEvent: event.codEvent,
                      codConnectionMode: event.codConnectionMode,
                      label: event.label,
                    })}
                    key={event.description}
                  />
                </Tooltip>
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
      </Grid2>
    );
  }
}

export default EventsItems;
