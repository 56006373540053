import React, { Component } from 'react'
import StyledStepper from "../componentsUI/styledComponents/styledStepper"
import { Step, StepButton } from '@mui/material'
import { connect } from 'react-redux'
import * as appStateReducer from '../store/app-state/reducer'
import { clearSteps } from "../store/app-state/actions"
import Translate from '../i18n/translate'

class ConectorStepper extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...props
		}
	}

	componentWillReceiveProps(props) {
		this.setState({...props});
	}

	componentWillUnmount = () => {
		this.props.clearSteps();
	}

	render() {
		const { 
			steps,
			currentStep,
			disableStepper
		} = this.state;

		return (
			<StyledStepper 
				activeStep={currentStep} 
				nonLinear={true} 
				alternativeLabel>
				{steps.map((item, index) => {
					let stepClass = disableStepper ? 'disabled' : 'completed'

					return (
						<Step 
							key={item.label}
							className={stepClass}>
							<StepButton onClick={() => {
								if(disableStepper) return
								else this.props.handleStep(index + 1)
							}}>
								{Translate(item.label)}
							</StepButton>                       
						</Step>
					);
				})}
			</StyledStepper>
		)
	}
}

const mapStateToProps = store => {
	return {
		steps: appStateReducer.getSteps(store)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		clearSteps: () => { dispatch(clearSteps()) }
	}
}

ConectorStepper.defaultProps = {
	steps: []
}

ConectorStepper = connect(
	mapStateToProps, 
	mapDispatchToProps
)(ConectorStepper);
export default ConectorStepper