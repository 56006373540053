import React from "react"
import styled from "styled-components"
import Translate from "../i18n/translate"
import { Typography } from '@mui/material'

const EventNameStyle = styled(Typography)`
	&& {
		width: 100%;
		text-align: center;
		font-size: 1.0rem;
		display: inline-block;
		color: #6F8296;
		margin-top: 2px;

		&:not(.without-margin) {
			padding: 3px 0;
			margin-bottom: 2px;
		}
	}`

const EventTypeStyle = styled(Typography)`
	&& {
		width: 100%;
		text-align: left;
		font-size: 1.2rem;
		display: inline-block;
		color: #6F8296;

		&:not(.without-margin) {
			padding: 3px 0;
			margin-bottom: 20px;
		}
	}`

export function EventName({title, className}) {
	return (<EventNameStyle className={className}>{Translate(title)}</EventNameStyle>)
}

export function EventType({title, className}) {
	return (<EventTypeStyle className={className}>{Translate(title)}</EventTypeStyle>)
}