import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { setBreadcrumb } from "../../store/app-state/actions"
import Translate from "../../i18n/translate"
import { Grid2, Typography, Tooltip } from "@mui/material"
import { MdAddCircle, MdDelete, MdEdit, MdVisibility, MdFileCopy } from "react-icons/md"
import BanksService from "./bank-accounts-service"
import Toast from "../../components/toast/toast"
import Session from "../../utils/session-info"
import ConectorSelect from "../../componentsUI/conectorSelect"
import ConectorInputText from "../../componentsUI/inputText"
import Loading from "../../componentsUI/loading"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"
import {
	bankImgLogo,
	bankAccountTitles,
	BanksCards,
	AccountsCards,
	addButton,
	labelButton,
	IconButtonContainerStyle,
	IconButtonsBanksCardContainer,
	IconButtonsAccountsCardContainer
} from "../../componentsUI/styledComponents/styledBankAccounts"

class BankAccounts extends Component {
	constructor(props) {
		super(props);

		props.setBreadcrumb([{
			label: "integrations"
		}, {
			label: "bank_accounts"
		}])

		this.state = {
			userAccessLevel: 0,
			deleteConfirmationModalProperties: undefined,
			loading: true,
			inputValueSearchBank: "",
			inputValueSearchAccount: "",
			inputBankOptionSelected: undefined,
			inputBanksOptions: new Array,
			cardBankSelected: undefined,
			listAddedBanks: new Array,
			listFilteredAddedBanks: new Array,
			accountsList: new Array,
			listFilteredAccounts: new Array
		}
	}

	componentWillMount = () => {
		BanksService.GetUserPermissionAccessLevelBankAccounts()
			.then(response => {
				if (response.status === 401) throw new Error("notHavePermissionToAccessBankAccounts")
				else return response.json()
			})
			.then(response => this.setState({ userAccessLevel: response.accessLevel }, this.getBanks()))
			.catch(error => {
				Toast.error(Translate(error.message));
				setTimeout(() => {
					window.location.href = '/';
				}, 2000);
			});
	}

	generateBankLogoImagePath(codBank) {
		let bankLogoName = "Gesplan"

		switch (codBank) {
			case "237":
				bankLogoName = "Bradesco"
				break;

			case "341":
				bankLogoName = "Itaú"
				break;

			case "033":
				bankLogoName = "Santander"
				break;

			case "001":
				bankLogoName = "BB"
				break;
		}

		return `/static/images/banksLogos/${bankLogoName}.png`
	}

	getBanks() {
		BanksService.GetBanks().then((banks) => {
			let inputBanksOptions = []

			if (banks != undefined) {
				inputBanksOptions = banks.map(bank => {
					return {
						value: bank.cod_bank,
						label: `${bank.cod_bank} - ${bank.legal_name}`,
						legal_name: bank.legal_name,
						trade_name: bank.trade_name
					}
				})
			}

			this.setState({ inputBanksOptions }, () => this.getAccounts())
		})
	}

	getAccounts() {
		BanksService.GetBankAccounts()
			.then(accounts => {
				if (accounts == undefined) accounts = []
				accounts = accounts.filter(account => account.cod_instance == Session().codInstance)
				this.getBanksFromAccountsAlreadyRegistered(accounts)
				this.setState({ accountsList: accounts, loading: false })
			})
	}

	getBanksFromAccountsAlreadyRegistered(bankAccounts) {
		const { inputBanksOptions } = this.state

		let listBanks = new Array

		bankAccounts.forEach(account => {
			inputBanksOptions.forEach(bankOption => {
				if (bankOption.value === account.cod_bank) listBanks.push(bankOption)
			})
		})

		const listAddedBanks = Array.from(new Set(listBanks.map(bank => JSON.stringify(bank)))).map(bank => JSON.parse(bank));

		this.setState({ listAddedBanks, listFilteredAddedBanks: listAddedBanks })
	}

	generateCardListAddedBanks() {
		const { listFilteredAddedBanks, userAccessLevel } = this.state

		return listFilteredAddedBanks.map(bankOption => {
			return (
				<BanksCards onClick={() => this.setCardBankSelected(bankOption.value)}>
					<Grid2 container spacing={1} wrap="nowrap" alignItems="center">
						<img src={this.generateBankLogoImagePath(bankOption.value)} style={bankImgLogo} />
						<Grid2 size={15}>
							<Typography variant="h6" style={bankAccountTitles}>{bankOption.legal_name}</Typography>
							<Typography variant="subtitle2" style={bankAccountTitles}>{bankOption.trade_name}</Typography>
						</Grid2>
						{userAccessLevel == 2 || userAccessLevel == 4 ?
							<Grid2>
								<div style={{ float: "right" }}>
									<Tooltip placement="left-end" title={<h3>{Translate("AddBankAccount")}</h3>}>
										<IconButtonsBanksCardContainer style={IconButtonContainerStyle} onClick={() => this.redirectToViewOrAddEditAccountPage(bankOption.value)}>
											<MdAddCircle size={24} /><label style={labelButton}>{Translate("AddAccount")}</label>
										</IconButtonsBanksCardContainer>
									</Tooltip>
									<Tooltip placement="left-end" title={<h3>{Translate("RemoveBank")}</h3>}>
										<IconButtonsBanksCardContainer style={IconButtonContainerStyle} onClick={() => this.removeBankFromList(bankOption.value)}>
											<MdDelete size={24} /><label style={labelButton}>{Translate("RemoveBank")}</label>
										</IconButtonsBanksCardContainer>
									</Tooltip>
								</div>
							</Grid2> : undefined}
					</Grid2>
				</BanksCards>
			)
		})
	}

	generateButtonsByAccessLevel = bankAccount => {
		const { userAccessLevel } = this.state

		if (userAccessLevel == 1) {
			return (
				<Tooltip placement="left-end" title={<h3>{Translate("ViewBankAccount")}</h3>}>
					<IconButtonContainer style={IconButtonContainerStyle} onClick={() => this.redirectToViewOrAddEditAccountPage(bankAccount.cod_bank, bankAccount.id_account)}>
						<MdVisibility size={20}/>
					</IconButtonContainer>
				</Tooltip>
			)
		}

		if (userAccessLevel == 2 || userAccessLevel == 4) {
			return (
				<Fragment>
					<Tooltip placement="left-end" title={<h3>{Translate("RemoveBankAccount")}</h3>}>
						<IconButtonsAccountsCardContainer style={IconButtonContainerStyle} onClick={() => this.openDeleteConfirmationModal(bankAccount)}>
							<MdDelete size={20}/>
						</IconButtonsAccountsCardContainer>
					</Tooltip>
					<Tooltip placement="left-end" title={<h3>{Translate("CopyBankAccount")}</h3>}>
						<IconButtonsAccountsCardContainer style={IconButtonContainerStyle} onClick={() => this.redirectToCopyAccountPage(bankAccount.cod_bank, bankAccount.id_account)}>
							<MdFileCopy size={20}/>
						</IconButtonsAccountsCardContainer>
					</Tooltip>
					<Tooltip placement="left-end" title={<h3>{Translate("EditBankAccount")}</h3>}>
						<IconButtonsAccountsCardContainer style={IconButtonContainerStyle} onClick={() => this.redirectToViewOrAddEditAccountPage(bankAccount.cod_bank, bankAccount.id_account)}>
							<MdEdit size={20}/>
						</IconButtonsAccountsCardContainer>
					</Tooltip>
				</Fragment>
			)
		}
	}

	generateCardListAddedAccounts() {
		const { listFilteredAccounts } = this.state

		return listFilteredAccounts.map(account => {
			return (
				<AccountsCards>
					<Grid2 container alignItems="center">
						<Grid2 size={5}>
							{account.account_digit == undefined || account.account_digit == '' ?
								<Typography variant="h6" style={bankAccountTitles}>{`C/C ${account.account}`}</Typography>
								: <Typography variant="h6" style={bankAccountTitles}>{`C/C ${account.account}-${account.account_digit}`}</Typography>
							}
							{account.bank_branch_digit == undefined || account.bank_branch_digit == '' ?
								<Typography variant="subtitle2" style={bankAccountTitles}>{`AG ${account.bank_branch}`}</Typography>
								: <Typography variant="subtitle2" style={bankAccountTitles}>{`AG ${account.bank_branch}-${account.bank_branch_digit}`}</Typography>
							}
						</Grid2>
						<Grid2 size={7}>
							{this.generateButtonsByAccessLevel(account)}
						</Grid2>
					</Grid2>
				</AccountsCards>
			)
		})
	}

	setInputValueSearchBank(inputSearchValue) {
		this.setState({
			inputValueSearchBank: inputSearchValue
		}, () => this.filterListAddedBanks())
	}

	setInputValueSearchAccount(inputSearchValue) {
		this.setState({
			inputValueSearchAccount: inputSearchValue
		}, () => this.filterListAccounts())
	}

	setCardBankSelected = codBank => this.setState({ cardBankSelected: codBank }, () => this.filterListAccounts())

	addBankToList() {
		const { listAddedBanks, inputBankOptionSelected } = this.state

		if (inputBankOptionSelected != undefined || inputBankOptionSelected != null) {
			let alreadyAdded = listAddedBanks.some(AddedBank => {
				return AddedBank.value == inputBankOptionSelected.value
			})

			if (alreadyAdded) Toast.error("BankAlreadyBeenAdded")
			else {
				listAddedBanks.push(inputBankOptionSelected)

				this.setState({ listAddedBanks }, () => {
					this.setInputValueSearchBank("")
					this.setInputValueSearchAccount("")
					this.setCardBankSelected(undefined)
				})
			}
		} else Toast.error("SelectBankOption")
	}

	redirectToViewOrAddEditAccountPage(codBank, idAccount = undefined) {
		if (idAccount == undefined) window.location.replace(`#/bank_accounts/${codBank}`)
		else window.location.replace(`#/bank_accounts/${codBank}/${idAccount}`)
	}

	redirectToCopyAccountPage = (codBank, idAccount) => window.location.replace(`#/bank_accounts/${codBank}/${idAccount}_copy`)

	removeBankFromList(codBank) {
		const { listAddedBanks, accountsList } = this.state

		let existAccounts = accountsList.some(account => { return account.cod_bank == codBank })

		if (existAccounts) Toast.error("notPossibleDeleteThisBank")
		else {
			listAddedBanks.forEach((AddedBank, index) => {
				if (AddedBank.value == codBank) listAddedBanks.splice(index, 1)
			})

			this.setState({ listAddedBanks }, () => {
				this.setInputValueSearchBank("")
				this.setInputValueSearchAccount("")
				this.setCardBankSelected(undefined)
			})
		}
	}

	openDeleteConfirmationModal(account) {
		this.setState({
			deleteConfirmationModalProperties: {
				type: "danger",
				title: "deleteConfirmation",
				message: "reallyWantDeleteBankAccount",
				open: true,
				onDismiss: () => this.setState({ deleteConfirmationModalProperties: { open: false } }),
				onClose: () => this.removeAccountFromList(account)
			}
		})
	}

	removeAccountFromList(account) {
		let idAccount = account.id_account
		let cod_bank = account.cod_bank

		BanksService.DeleteAccount(idAccount, cod_bank)
			.then(response => {
				if (response.status === 401) throw new Error("notHavePermissionToDeleteBankAccounts")
				if (response.status === 409) throw new Error("AccountExistsInStructure")
				else return response.json()
			})
			.then(() => {
				BanksService.GetBankAccounts()
					.then(accounts =>
						this.setState({
							deleteConfirmationModalProperties: { open: false },
							accountsList: accounts
						}, () => {
							this.setInputValueSearchBank("")
							this.setInputValueSearchAccount("")
							this.setCardBankSelected(cod_bank)
							this.getBanksFromAccountsAlreadyRegistered(this.state.accountsList)
						}
						)
					)
			})
			.catch(error => {
				this.setState({ deleteConfirmationModalProperties: { open: false } }, () => Toast.error(Translate(error.message)))
			})
	}

	filterListAddedBanks() {
		const { listAddedBanks, inputValueSearchBank } = this.state

		let listFilteredAddedBanks = listAddedBanks.filter(bank => (
			bank.legal_name.toLowerCase().includes(inputValueSearchBank.toLowerCase())
		))

		this.setState({ listFilteredAddedBanks })
	}

	filterListAccounts() {
		const { accountsList, inputValueSearchAccount, cardBankSelected } = this.state

		let listFilteredAccounts = accountsList.filter(account => (
			account.account.toLowerCase().includes(inputValueSearchAccount.toLowerCase())
		)).filter(account => { if (account.cod_bank == cardBankSelected) return account })

		this.setState({ listFilteredAccounts })
	}

	render() {
		const {
			loading,
			userAccessLevel,
			deleteConfirmationModalProperties,
			inputValueSearchBank,
			inputValueSearchAccount,
			inputBanksOptions,
			inputBankOptionSelected
		} = this.state

		if (loading) return <Loading />

		return (
			<Fragment>
				<InternalPageTitle title={Translate("bank_accounts")} />
				<Grid2 container spacing={1}>
					<Grid2 size={6}>
						<Grid2 spacing={2}>
							{userAccessLevel == 2 || userAccessLevel == 4 ?
								<Grid2>
									<StyledPaper>
										<Grid2>
											<ConectorSelect
												placeholder="SelectBank"
												onChange={selectedOption => this.setState({ inputBankOptionSelected: selectedOption })}
												options={inputBanksOptions} />
											<Grid2 container justifyContent="center">
												<StyledButton
													variant="outlined"
													disabled={inputBankOptionSelected == undefined ? true : false}
													onClick={() => this.addBankToList()}
													style={addButton}>
													<Grid2 container spacing={0.5}>
														<Grid2 container><MdAddCircle size={25} /></Grid2>
														<Grid2 container>{Translate("AddBank")}</Grid2>
													</Grid2>
												</StyledButton>
											</Grid2>
										</Grid2>
									</StyledPaper>
								</Grid2> : undefined}
							<Grid2>
								<StyledPaper>
									<ConectorInputText
										value={inputValueSearchBank}
										onChange={element => { this.setInputValueSearchBank(element.target.value) }}
										placeholder="SearchBank" />
									{this.generateCardListAddedBanks()}
								</StyledPaper>
							</Grid2>
						</Grid2>
					</Grid2>
					<Grid2 size={6}>
						<Grid2>
							<StyledPaper>
								<ConectorInputText
									value={inputValueSearchAccount}
									onChange={element => { this.setInputValueSearchAccount(element.target.value) }}
									placeholder="SearchAccount" />
								{this.generateCardListAddedAccounts()}
							</StyledPaper>
						</Grid2>
					</Grid2>
				</Grid2>

				<ConectorDialogConfirmation dialogOptions={deleteConfirmationModalProperties} />
			</Fragment>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(null, mapDispatchToProps)(BankAccounts);