import userService from "../../src/store/login/service"

let changeUserInterfaceSettingsRequest = null

const saveUserInterfaceSettings = userObjectLocalStorage => {
  localStorage.setItem('user', JSON.stringify(userObjectLocalStorage))

  if (!changeUserInterfaceSettingsRequest) {
    changeUserInterfaceSettingsRequest = setTimeout(() => {
      userService.changeUserInterfaceSettings()
      changeUserInterfaceSettingsRequest = null
    }, 5000)
  }
}

export default saveUserInterfaceSettings