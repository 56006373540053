import request from '../../utils/request'

export default {
    GetPermissionsHistory: codCompany => {
        return request.executeRequest(
            `${request.baseUrl}permissions_history/${codCompany}`, request.getOptions()
        ).then(res => res.json())
    },
    GetAllCompaniesToPermissionsHistory: () => {
        return request.executeRequest(
            `${request.baseUrl}permissions_history/get/allCompanies`,
            request.getOptions()
        ).then(res => res.json())
    }
}