import styled from "styled-components"
import { Tabs, Dialog, IconButton } from "@mui/material"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

export const StyledDialog = styled(Dialog)`
    && {
        z-index: 1300;

        &>.MuiDialog-container {
            &>.MuiPaper-root {
                overflow: visible;
                
                &>.MuiDialogContent-root {
                    overflow: visible;
                }

                & > .MuiDialogActions-root {
                    margin: 8px 24px 24px;

                    .MuiButtonBase-root:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
`;

export const StyledIconButton = styled(IconButton)`
    && {
        padding: 6px 6px 6px 3px;

        > span[class^="MuiIconButton-label-"] {
            font-size: 12px;

            > svg {
                margin-right: 3px;
            }
        }
    }
`;

export const StyledGridButton = styled(StyledButton)`

    && { 
        min-width: 50px;
        margin: 0 20px 20px 0;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width:500px) {
            min-width: calc(50% - 13px);
            &:last-child { min-width: calc(100% - 15px); }
        }
    }

`;

export const StyledTabs = styled(Tabs)`
    && {
        button {
            color: #6F8296;
            font-size: 0.75rem;
            overflow: visible;
            
            border-top: 1px solid #F2F2F2;
            border-left: 1px solid #F2F2F2;
            border-right: 1px solid #F2F2F2;

            &:hover, &.Mui-selected {
                background-color: #fff;
                border-top: 1px solid #B8C6D6;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        .MuiTabs-fixed {
            overflow-x: initial;
        }

        .MuiTabs-indicator {
            height: 0;
            background-color: #B8C6D6;
        }
    }
`;

export const StyledFloatingTabsMenu = styled.div `
    position: sticky;
    top: 0px;
    height: auto;
`;
