import React, { Component } from "react"
import { connect } from "react-redux"
import { MdForward } from "react-icons/md"
import { getDetailInInfo } from "../../../store/details/reducer"
import { getAllDetailsIn } from "../../../store/details/actions"
import * as inputSelector from "../../../store/interfaces/reducer"
import * as wizardStore from "../../../store/wizard/wizard-store-reducer"
import { setWizardState } from "../../../store/wizard/wizard-store-actions"
import Translate from "../../../i18n/translate"
import Filter from "./filter"
import ObjectUtils from "../../../utils/object-utils"
import cloneDeep from 'lodash/cloneDeep'
import { Grid2, Tooltip } from "@mui/material"
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import ConectorSelect from "../../../componentsUI/conectorSelect"
import Loading from "../../../componentsUI/loading"
import { StyledSortableTree } from "../../../componentsUI/styledComponents/styledSortableTree"
import { StyledIconButton } from "../../../componentsUI/styledComponents/styledTransform"
import getDetailTreeHeightInPixels from "../../../utils/getDetailTreeHeightInPixels"

class FilterStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            detailsIn: [],
            detailsInLoading: false,
            detailInSelected: {
                value: 0,
                label: ""
            },
            detailusada: this.props.detailsIn
        };

        props.setWizardState({ disabledStep: false })
    }

    componentWillMount() {
        let { wizardState } = this.state;

        if (wizardState.isEdit) {
            let structureDetail = wizardState.event.structures[0].structureDetails[0];

            this.handleChangeInputLookup({
                label: structureDetail.input,
                value: structureDetail.idDetailInput
            });
        } else {
            wizardState.event.structures = [{
                codConnectionAction: wizardState.event.codConnectionAction,
                codConnectionType: wizardState.event.codConnectionType,
                codInstanceConnection: wizardState.event.codInstanceConnection,
                codEventProperty: 126,
                codInstance: wizardState.event.codInstance,
                structureDetails: [{
                    codInstance: wizardState.event.codInstance,
                    codStructureDetail: 1,
                    title: "Filter Detail",
                    output: "",
                    codMaster: 0,
                    indPosition: 0,
                    fields: [{
                        codInstance: wizardState.event.codInstance,
                        indPosition: 0,
                        desField: "Filter Field",
                        codAttributeGroup: 33,
                        desSubaction: "Filter",
                        fieldTransforms: []
                    }]
                },],
                structureExtras: [{
                    codStructureExtra: 67,
                    value: "inclusive"
                }],
                isMain: true,
                indPosition: 1,
                codMaster: 0
            }, {
                codConnectionAction: wizardState.event.codConnectionAction,
                codConnectionType: wizardState.event.codConnectionType,
                codInstanceConnection: wizardState.event.codInstanceConnection,
                codEventProperty: 112,
                codInstance: wizardState.event.codInstance,
                isMain: true,
                indPosition: 2,
                codMaster: 0,
                structureDetails: []
            }];
        }
    }

    handleChangeInputLookup = detailInSelected => {
        this.state.wizardState.event.structures[0].structureDetails[0].input = detailInSelected.label;
        this.state.wizardState.event.structures[0].structureDetails[0].idDetailInput = detailInSelected.value;

        this.props.getAllDetailsIn(detailInSelected.value);

        this.setState({
            nodeSelected: null,
            detailInSelected: detailInSelected,
            detailsInLoading: true
        });
    }

    componentWillReceiveProps(props) {
        const {
            detailInSelected,
            wizardState
        } = this.state;

        if (detailInSelected.value != 0) {
            this.setState({
                detailsIn: this.buildDetails(props.detailsIn),
                detailsInLoading: false,
                detailsOut: props.detailsIn
            }, () => {
                if (!wizardState.isEdit) {
                    let structureDetails = ObjectUtils.deepCopy(props.detailsIn);
                    structureDetails.map(structureDetail => this.doCleanStructureDetailReference(structureDetail));

                    wizardState.event.structures.find(structure => structure.codEventProperty === 112).structureDetails = this.getDetailsToSave(structureDetails);

                    this.setState({ wizardState: wizardState });
                }
            });
        }
    }

    doCleanStructureDetailReference = (structureDetail) => {
        const { detailsIn } = this.state;

        structureDetail.idDetailInput = detailsIn[0].codStructure;
        structureDetail.input = detailsIn[0].output;
        structureDetail.output = "";

        delete structureDetail.idStructureDetail;
        delete structureDetail.codStructure;

        if (structureDetail.structureExtras) {
            structureDetail.structureExtras.map(structureExtra => {
                delete structureExtra.codStructure;
                delete structureExtra.codStructureDetail;
                delete structureExtra.codStructureExtraValues;
            });
        } else {
            structureDetail.structureExtras = [];
        }

        if (structureDetail.children) {
            structureDetail.children.map(child => {
                this.doCleanStructureDetailReference(child);
            });
        }

        if (structureDetail.fields) {
            structureDetail.fields.map(field => this.doCleanFieldReference(field));
        }
    }

    doCleanFieldReference = (field) => {
        field.fieldAttributes.map(attribute => {
            delete attribute.codField;
        });

        if (field.fieldTransforms) {
            field.fieldTransforms.map(transform => {
                delete transform.codFieldTransform;
                delete transform.codField;

                transform.fieldTransformAttributes.map(transformAttribute => {
                    delete transformAttribute.codFieldTransform;
                });
            });
        }

        delete field.id;
        delete field.codField;
    }

    getDetailsToSave = (detailSources, indPosition = 1, codMaster = 0) => {
        let detailDests = [];

        detailSources.map(detailSource => {
            const detailChildSources = detailSource.children;

            let detailDest = {
                ...detailSource,
                codStructureDetail: indPosition,
                indPosition: indPosition++,
                codMaster: codMaster
            };

            delete detailDest.children;

            if (detailChildSources) {
                const detailChildDests = this.getDetailsToSave(detailChildSources, indPosition, detailDest.indPosition);

                indPosition += detailChildDests.length;
                detailDests = detailDests.concat(detailChildDests);
            }

            detailDests.push(detailDest);
        });

        return detailDests;
    }

    buildDetails = (detailSources, parentDetail = null) => {
        let detailDests = [];

        detailSources.map(detailSource => {
            let detailDest = {
                ...detailSource,
                path: ((parentDetail != null ? (parentDetail.path + ".") : "") + detailSource.title),
                expanded: true
            };

            if (detailSource.fields || detailSource.children) {
                detailDest.children = [];
            }

            if (detailSource.fields) {
                detailDest.children = this.buildDetailFields(detailSource.fields, detailDest);
            }

            if (detailSource.children) {
                const childrens = this.buildDetails(detailSource.children, detailDest);
                detailDest.children = detailDest.children.concat(childrens);
            }

            detailDests.push(detailDest);
        });

        return detailDests;
    }

    buildDetailFields = (fields, parentDetail) => {
        return fields.map(field => {
            return {
                title: field.fieldAttributes.find(attribute => attribute.codAttribute == 11).desValue,
                path: parentDetail.path
            }
        });
    }

    renderInputModelComponent = () => {
        const {
            detailInSelected,
            wizardState
        } = this.state;

        return (
            <ConectorSelect
                label={"input_model"}
                help={"select_input_model"}
                placeholder={"choose"}
                options={this.getInputLookupList()}
                onChange={this.handleChangeInputLookup}
                value={detailInSelected}
                disabled={wizardState.isEdit} />
        )
    }

    getOutputStructuresFromEvent = interfaceEvents => {
        let outputStructures = new Array

        interfaceEvents.filter(event => event.structures)
            .forEach(event => {
                event.structures.forEach(structure => {
                    if (structure.structureDetails) {
                        const outputStructure = structure.structureDetails.find(detail => {
                            return detail.output
                        });

                        if (outputStructure) outputStructures.push(outputStructure);
                    }
                })
            })

        return outputStructures
    }

    getInputLookupList = () => {
        const { events, wizardState } = this.state;
        const eventThatWillBeCreated = wizardState.event;

        let pastEvents = events.filter(event => event.indPosition < eventThatWillBeCreated.indPosition)
        let outputStructuresFromPastEvents = this.getOutputStructuresFromEvent(pastEvents)

        return outputStructuresFromPastEvents.map(input => {
            return {
                label: input.output,
                value: input.codStructure
            }
        })
    }

    renderLoading = () => {
        return (
            <StyledPaper style={{ marginTop: "15px" }}>
                <Loading />
            </StyledPaper>
        )
    }

    renderInputTreeComponent = () => {
        const { detailsIn } = this.state;

        if (this.props.wizardState.renderedWizardContent === false) {
            this.props.setWizardState({ renderedWizardContent: true })
        }

        return (
            <div style={{ marginTop: "15px", height: `${getDetailTreeHeightInPixels(detailsIn)}px` }}>
                <StyledSortableTree
                    treeData={detailsIn}
                    canDrag={false}
                    onChange={detailsIn => this.setState({ detailsIn: detailsIn })}
                    generateNodeProps={({ node }) => this.getInputTreeComponentProps(node)}
                    isVirtualized={false} />
            </div>
        )
    }

    disableSaveEventButton = () => this.props.setWizardState({ disabledStep: true })

    getInputTreeComponentProps(item) {
        let buttons = new Array;

        if (!item.hasOwnProperty("children")) {
            buttons.push(
                <Tooltip title={Translate("add")}>
                    <StyledIconButton onClick={() => { this.setState({ nodeSelected: item }, () => this.disableSaveEventButton()) }}>
                        <MdForward size={15} />
                    </StyledIconButton>
                </Tooltip>
            )
        }

        return { buttons };
    }

    render() {
        const {
            nodeSelected,
            detailInSelected,
            detailsInLoading
        } = this.state;

        const nodeSelectedClone = cloneDeep(nodeSelected)

        return (
            <Grid2 container spacing={1}>
                <Grid2 size={6}>
                    {this.renderInputModelComponent()}
                    {detailsInLoading ? this.renderLoading() : this.renderInputTreeComponent()}
                </Grid2>
                <Grid2 size={6}>
                    <Filter nodeSelected={nodeSelectedClone} detailIn={detailInSelected} />
                </Grid2>
            </Grid2>
        )
    }
}

const mapStateToProps = state => {
    const [inputs] = inputSelector.getInputs(state);
    const [events] = inputSelector.getEvents(state);
    const detailsIn = getDetailInInfo(state);

    return {
        inputs,
        events,
        detailsIn,
        wizardState: wizardStore.getWizardState(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllDetailsIn: (codDetail) => { dispatch(getAllDetailsIn(codDetail)) },
        setWizardState: wizardState => dispatch(setWizardState(wizardState))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterStep);