import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setBreadcrumb } from "../../store/app-state/actions";
import Translate from "../../i18n/translate";
import Toast from "../../components/toast/toast";
import BanksService from "./bank-accounts-service";
import { Grid2 } from "@mui/material";
import Loading from "../../componentsUI/loading"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import ConectorInputText from "../../componentsUI/inputText"
import ConectorInputPassword from "../../componentsUI/inputPassword"
import ConectorSelect from "../../componentsUI/conectorSelect"

class AddEditBankAccount extends Component {
    constructor(props) {
        super(props);

        let codBankURL = window.location.hash.substring(16, 19)
        let idAccountURL = window.location.hash.substring(20)

        props.setBreadcrumb([{
            label: "integrations"
        }, {
            label: "bank_accounts",
            url: "#/bank_accounts"
        }, {
            label: Translate(idAccountURL === "" ? "AddBankAccount" : "ViewEditOrCopyBankAccount")
        }])

        this.state = {
            userAccessLevel: 0,
            operationType: undefined,
            loading: true,
            idAccountURL,
            id_account: undefined,
            cod_bank: codBankURL,
            bank_branch: undefined,
            bank_branch_digit: undefined,
            account: undefined,
            account_digit: undefined,
            client_id: undefined,
            client_secret: undefined,
            pfx_path: undefined,
            environments: [{ value: 1, label: 'Sandbox' }, { value: 2, label: 'Produção' }],
            selectedEnvironmentOption: undefined,
            certificate_password: undefined,
            directory: undefined,
            application_key: undefined
        }
    }

    componentWillMount() {
        const { idAccountURL, cod_bank } = this.state

        BanksService.GetUserPermissionAccessLevelBankAccounts()
            .then(response => {
                if (response.status === 401) throw new Error("notHavePermissionToAccessBankAccounts")
                else return response.json()
            })
            .then(response => {
                let operationType

                if (idAccountURL.includes("_copy")) operationType = "Copy"
                else operationType = idAccountURL == "" ? "Add" : "Edit"

                return { userAccessLevel: response.accessLevel, operationType }
            })
            .then(({ userAccessLevel, operationType }) => {
                this.setState({ userAccessLevel, operationType }, () => {
                    let idAccountFromURL = operationType == "Add" ? undefined : parseInt(idAccountURL)

                    this.getAccount(idAccountFromURL, cod_bank)
                })
            })
            .catch(error => {
                Toast.error(Translate(error.message));
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            })
    }

    getAccount(idAccount, codBank) {
        const { operationType } = this.state

        BanksService.GetBankAccount(idAccount)
            .then(bankAccount => this.setState({
                id_account: operationType == "Copy" ? undefined : idAccount,
                account: operationType == "Copy" ? undefined : bankAccount.account,
                account_digit: operationType == "Copy" ? undefined : bankAccount.account_digit,
                cod_bank: codBank,
                bank_branch: bankAccount.bank_branch,
                bank_branch_digit: bankAccount.bank_branch_digit,
                client_id: bankAccount.client_id,
                client_secret: bankAccount.client_secret,
                pfx_path: bankAccount.pfx_path,
                selectedEnvironmentOption: bankAccount.environment,
                certificate_password: bankAccount.certificate_password,
                directory: bankAccount.directory,
                application_key: bankAccount.application_key,
                loading: false
            }))
    }

    recordData(redirect = true) {
        const {
            account,
            account_digit,
            certificate_password,
            bank_branch,
            bank_branch_digit,
            cod_bank,
            client_id,
            client_secret,
            id_account,
            directory,
            pfx_path,
            selectedEnvironmentOption,
            application_key,
            operationType
        } = this.state

        let bankAccount = {
            "id_account": id_account,
            "cod_bank": cod_bank,
            "bank_branch": bank_branch,
            "bank_branch_digit": bank_branch_digit === "" || undefined ? null : bank_branch_digit,
            "account": account,
            "account_digit": account_digit === "" || undefined ? null : account_digit,
            "client_id": client_id,
            "client_secret": client_secret,
            "certificate_password": certificate_password,
            "directory": directory,
            "pfx_path": pfx_path,
            "environment": selectedEnvironmentOption,
            "application_key": application_key
        }

        if (operationType == "Copy" || id_account == undefined) this.saveAccount(bankAccount, redirect)
        else this.editAccount(bankAccount, redirect)
    }

    resetToEmptyInputs() {
        this.setState({
            account: '',
            account_digit: '',
            bank_branch: '',
            bank_branch_digit: '',
            selectedEnvironmentOption: '',
            client_id: '',
            client_secret: '',
            directory: '',
            pfx_path: '',
            certificate_password: '',
            application_key: ''
        })
    }

    saveAccount(bankAccount, redirect) {
        this.resetToEmptyInputs()

        BanksService.RegisterAccount(bankAccount)
            .then(response => {
                if (response.status === 401) throw new Error("notHavePermissionToRegisterBankAccounts")
                else return response.json()
            })
            .then((response) => {
                if (response == "Bank account already exists") {
                    Toast.error(Translate("BankAccountAlreadyExists"))
                    redirect = false
                } else {
                    Toast.success(`${Translate('account')} 
                ${bankAccount.account}
                ${Translate('fromTheBankBranch')}
                ${bankAccount.bank_branch}
                ${Translate('savedSuccessfully')}`)
                }
            })
            .catch(error => Toast.error(Translate(error.message)))
            .finally(() => { if (redirect) this.redirectURL() })
    }

    editAccount(bankAccount, redirect) {
        this.resetToEmptyInputs()

        BanksService.EditAccount(bankAccount)
            .then(response => {
                if (response.status === 401) throw new Error("notHavePermissionToEditBankAccounts")
                else return response.json()
            })
            .then(response => {
                if (response == "Bank account already exists") {
                    Toast.error(Translate("BankAccountAlreadyExists"))
                    redirect = false
                } else {
                    Toast.success(`${Translate('account')} 
                ${bankAccount.account}
                ${Translate('fromTheBankBranch')}
                ${bankAccount.bank_branch}
                ${Translate('savedSuccessfully')}`)
                }
            })
            .catch(error => Toast.error(Translate(error.message)))
            .finally(() => { if (redirect) this.redirectURL() })
    }

    checkUserAccessLevelAndEmptyInputs() {
        const {
            cod_bank,
            account,
            bank_branch,
            client_id,
            client_secret,
            directory,
            pfx_path,
            certificate_password,
            selectedEnvironmentOption,
            application_key,
            userAccessLevel
        } = this.state

        if (userAccessLevel == 1) return true

        if (cod_bank == '001') {
            if (application_key == undefined) return true
            if (application_key.trim().length == 0) return true
        }

        if ((account != undefined && account.trim().length == 0)
            || (bank_branch != undefined && bank_branch.trim().length == 0)
            || (client_id != undefined && client_id.trim().length == 0)
            || (client_secret != undefined && client_secret.trim().length == 0)
            || (directory != undefined && directory.trim().length == 0)
            || (pfx_path != undefined && pfx_path.trim().length == 0)
            || (certificate_password != undefined && certificate_password.trim().length == 0)
            || (selectedEnvironmentOption == undefined || selectedEnvironmentOption == 0))
            return true
        else return false
    }

    redirectURL = () => window.location.replace(`#/bank_accounts`)

    CPFInputMask(valueCPF) {
        valueCPF = valueCPF.replace(/\D/g, "")

        if (valueCPF.length <= 11) {
            valueCPF = valueCPF.replace(/(\d{3})(\d)/, "$1.$2")
            valueCPF = valueCPF.replace(/(\d{3})(\d)/, "$1.$2")
            valueCPF = valueCPF.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        } else {
            valueCPF = valueCPF.replace(/^(\d{2})(\d)/, "$1.$2")
            valueCPF = valueCPF.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
            valueCPF = valueCPF.replace(/\.(\d{3})(\d)/, ".$1/$2")
            valueCPF = valueCPF.replace(/(\d{4})(\d)/, "$1-$2")
        }

        this.setState({ cpf: valueCPF })
    }

    limitInputNumberOfCharacters(inputDigit, inputName) {
        switch (inputName) {
            case "bankBranchDigit":
                if (inputDigit.length === 1 || inputDigit.length === 0) this.setState({ bank_branch_digit: inputDigit })
                break;

            case "accountDigit":
                if (inputDigit.length === 1 || inputDigit.length === 0) this.setState({ account_digit: inputDigit })
                break;
        }
    }

    generatePageTitle = () => {
        const { id_account, userAccessLevel, operationType } = this.state

        if (userAccessLevel == 1) return 'ViewBankAccount'
        else {
            if (operationType == "Copy") return 'CopyBankAccount'
            else return id_account == undefined ? 'AddBankAccount' : 'EditBankAccount'
        }
    }

    renderBankAccountForm = () => {
        const {
            id_account,
            bank_branch,
            bank_branch_digit,
            account,
            account_digit,
            client_id,
            client_secret,
            pfx_path,
            environments,
            selectedEnvironmentOption,
            certificate_password,
            directory,
            application_key,
            cod_bank,
            userAccessLevel
        } = this.state

        return (
            <Fragment>
                <Grid2 container columnSpacing={2} spacing={1}>
                    <Grid2 size={5}>
                        <ConectorInputText
                            label="bankBranch"
                            value={bank_branch}
                            required={true}
                            disabled={userAccessLevel === 1}
                            onChange={(data) => this.setState({ bank_branch: data.target.value.trim() })} />
                    </Grid2>
                    <Grid2 size={1}>
                        <ConectorInputText
                            label="digit"
                            value={bank_branch_digit}
                            disabled={userAccessLevel === 1}
                            onChange={data => this.limitInputNumberOfCharacters(data.target.value.trim(), "bankBranchDigit")} />
                    </Grid2>
                    <Grid2 size={5}>
                        <ConectorInputText
                            label="account"
                            value={account}
                            required={true}
                            disabled={userAccessLevel === 1}
                            onChange={(data) => this.setState({ account: data.target.value.trim() })} />
                    </Grid2>
                    <Grid2 size={1}>
                        <ConectorInputText
                            label="digit"
                            value={account_digit}
                            disabled={userAccessLevel === 1}
                            onChange={data => this.limitInputNumberOfCharacters(data.target.value.trim(), "accountDigit")} />
                    </Grid2>
                    <Grid2 size={3}>
                        <ConectorSelect
                            label={'environment'}
                            required={true}
                            name={'environment'}
                            value={environments.find(option => option.value === selectedEnvironmentOption) || null}
                            options={environments}
                            disabled={userAccessLevel === 1}
                            onChange={option => this.setState({ selectedEnvironmentOption: option.value })} />
                    </Grid2>
                    <Grid2 size={5}>
                        <ConectorInputText
                            label="Client ID"
                            value={client_id}
                            required={true}
                            disabled={userAccessLevel === 1}
                            onChange={(data) => this.setState({ client_id: data.target.value.trim() })} />
                    </Grid2>
                    <Grid2 size={4}>
                        <ConectorInputPassword
                            label="Client Secret"
                            value={client_secret}
                            required={true}
                            disabled={userAccessLevel === 1}
                            onChange={(data) => this.setState({ client_secret: data.target.value.trim() })} />
                    </Grid2>
                    <Grid2 size={6}>
                        <ConectorInputText
                            label={'directory'}
                            value={directory}
                            required={true}
                            disabled={userAccessLevel === 1}
                            onChange={(data) => this.setState({ directory: data.target.value.trim() })} />
                    </Grid2>
                    <Grid2 size={6}>
                        <ConectorInputText
                            label={'PFXPath'}
                            value={pfx_path}
                            required={true}
                            disabled={userAccessLevel === 1}
                            onChange={(data) => this.setState({ pfx_path: data.target.value.trim() })} />
                    </Grid2>
                    <Grid2 size={6}>
                        <ConectorInputPassword
                            label={'certificatePassword'}
                            value={certificate_password}
                            required={true}
                            disabled={userAccessLevel === 1}
                            onChange={(data) => this.setState({ certificate_password: data.target.value.trim() })} />
                    </Grid2>
                    <Grid2 size={6}>
                        <ConectorInputText
                            invisible={cod_bank == '001' ? false : true}
                            label={'Application Key'}
                            value={application_key}
                            required={true}
                            disabled={userAccessLevel === 1}
                            onChange={(data) => this.setState({ application_key: data.target.value.trim() })} />
                    </Grid2>
                </Grid2>
            </Fragment>
        )
    }

    renderActionsFormButtons = () => {
        const { id_account, operationType } = this.state
        let continueButtonTitle
        let exitButtonTitle

        switch (operationType) {
            case "Add":
                continueButtonTitle = "SaveContinue"
                exitButtonTitle = "SaveExit"
                break;
            case "Edit":
                continueButtonTitle = "ModifyContinue"
                exitButtonTitle = "ModifyExit"
                break;
            case "Copy":
                continueButtonTitle = "CopyContinue"
                exitButtonTitle = "CopyExit"
                break;
        }
        
        return (
            <Grid2 container justifyContent="space-between" sx={{ marginTop: "10px" }}>
                <Grid2>
                    <StyledButton
                        variant="contained"
                        onClick={() => this.redirectURL()}>
                        {Translate("cancel")}
                    </StyledButton>
                </Grid2>
                <Grid2 container spacing={1}>
                    <StyledButton
                        variant="outlined"
                        disabled={this.checkUserAccessLevelAndEmptyInputs()}
                        onClick={() => this.recordData()}>
                        {Translate(exitButtonTitle)}
                    </StyledButton>
                    {id_account == undefined ?
                        <StyledButton
                            variant="outlined"
                            disabled={this.checkUserAccessLevelAndEmptyInputs()}
                            onClick={() => this.recordData(false)}>
                            {Translate(continueButtonTitle)}
                        </StyledButton> : null}
                </Grid2>
            </Grid2>
        )
    }

    render() {
        const { loading } = this.state

        if (loading) return <Loading/>

        return (
            <Fragment>
                <InternalPageTitle title={this.generatePageTitle()} />
                <StyledPaper>
                    {this.renderBankAccountForm()}
                    {this.renderActionsFormButtons()}
                </StyledPaper>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(null, mapDispatchToProps)(AddEditBankAccount);