import React, { Component } from 'react'
import WizardStructure from '../wizard-structure/wizard-structure'
import WizardStructureOld from '../wizard-structure/wizard-structure-old'
import BankEventStructure from '../bank-event-structure/bank-event-structure'
import { connect } from "react-redux"
import * as wizardStore from "../../../store/wizard/wizard-store-reducer"
import StructureAux from "../../../utils/structureAux"
import {StyledPaperRest, StyledDiv} from "../../../componentsUI/styledComponents/styledGenericStep"

class GenericStepStructure extends Component {
    constructor(props) {
        super(props)
        
        var { wizardState } = props

        this.state = {
            isSoapStructure: StructureAux.structureAux(wizardState.event.codConnectionType),
            codBank: wizardState.event.codBank,
            event: wizardState.event
        }
    }

    render() {
        const { isSoapStructure, event } = this.state

        if(event.codConnectionAction == 82){
            return (
                <StyledPaperRest className={'bg_darken_04'}>
                    <StyledDiv>
                        <BankEventStructure/>
                    </StyledDiv>
                </StyledPaperRest>
            )
        }

        return (
            <StyledPaperRest className={'bg_darken_04'}>
                <StyledDiv>
                    {isSoapStructure ? <WizardStructure/> : <WizardStructureOld/>}
                </StyledDiv>
            </StyledPaperRest>
        )
    }
}

const mapStateToProps = store => {
    return {wizardState: wizardStore.getWizardState(store)}
}

export default connect(mapStateToProps, null)(GenericStepStructure);