import { styled } from '@mui/system';

export const BodyDiv = styled('div')(() => ({
  '&& input:-internal-autofill-previewed, && input:-internal-autofill-selected': {
    backgroundColor: '#fff !important',
    backgroundImage: 'none !important',
    color: '#009CDF !important',
  },
}))

export const RoutesDiv = styled('div')(({ loggedIn, hiddenSidebar }) => ({
  paddingTop: 94,
  paddingBottom: 20,
  paddingLeft: 20,
  paddingRight: 20,
  transition: 'margin-left 0.5s',
  marginLeft: loggedIn ? (hiddenSidebar ? '70px' : '200px') : '0px'
}))