import React, {
    Component,
    Fragment
} from "react"
import { connect } from "react-redux"
import Session from "../../../utils/session-info"
import ObjectUtils from "../../../utils/object-utils"
import { getFlatDataFromTree } from "@nosferatu500/react-sortable-tree"
import Toast from "../../../components/toast/toast"
import { setStepStatus } from "../../../store/app-state/actions"
import * as inputSelector from "../../../store/interfaces/reducer"
import * as wizardStore from "../../../store/wizard/wizard-store-reducer"
import Translate from "../../../i18n/translate"
import WizardService from "../wizard-service"
import WizardStructureExtras from "./extras/wizard-structure-extras"
import WizardStructureDetailsTabs from "./details/wizard-structure-details-tabs"
import WizardStructureDetailsTree from "./details/wizard-structure-details-tree"
import { StyledTabs, StyledGrid } from "../../../componentsUI/styledComponents/styledWizardStructureTabsDetails"
import Loading from "../../../componentsUI/loading"
import { setWizardState, setWizardStructureArray, setStepStructure, setTreeData } from "../../../store/wizard/wizard-store-actions"
import { Tab, Grid2 } from "@mui/material";

class WizardStructureTabs extends Component {
    constructor(props) {
        super(props);

        var {
            isStepAuxiliary,
            stepProperties,
            attributes,
            attributeGroups,
            stepAttributes,
            wizardState,
            structureInInput,
            structureInputOptions,
            selectedInputFields,
            stepExtras,
            selectedNode,
            tabs,
            structuresArray,
            detailTabs,
            stepExtras,
            detailsExtrasAttributes,
            attributeGroupsDetails,
            newEvent
        } = props;

        tabs = detailTabs
        this.state = {
            stepProperties,
            stepStructure: [],
            structureIn: stepProperties.desEventProperty === 'structure_in',
            selectedTab: 0,
            tabs: [],
            isLoading: true,
            treeData: this.props.treeData,
            isStepAuxiliary,
            attributes,
            attributeGroups,
            stepAttributes,
            wizardState,
            structureInInput,
            structureInputOptions,
            selectedInputFields,
            stepExtras,
            selectedNode,
            tabs,
            structuresArray,
            detailsExtrasAttributes,
            attributeGroupsDetails,
            newEvent,
            codStructureToGetDetailsExtraValues: undefined,
            componentCodStructure: undefined,
            ...props
        }
    }

    componentWillMount = _ => {
        const { wizardState, stepStructure } = this.state
        
        if(wizardState.isEdit) {
            this.setState({
                codStructureToGetDetailsExtraValues: stepStructure.codStructure,
                componentCodStructure: stepStructure.codStructure}, () => {
                this.getDetailsExtraValues()
                this.handleWillMount()
            })
        } else this.handleWillMount()
    }

    handleWillMount = _ => {
        var {
            stepStructure,
            inputs,
            structureIn
        } = this.state;

        if (structureIn) {
            var structureInInput,
                stepInputs = [...inputs];

            if (stepStructure.structureDetails.length) {
                var options = stepStructure
                    .structureDetails
                    .filter(detail => detail.idDetailInput > 0)
                    .map(detail => detail.idDetailInput);

                structureInInput = stepInputs.find(structureDetail =>
                    options.includes(structureDetail.idStructureDetail)
                );
                if(structureInInput == undefined){
                    var options = stepStructure
                        .structureDetails
                        .map(detail => detail.input);

                    structureInInput = stepInputs.find(structureDetail =>
                        options.includes(structureDetail.output)
                    );
                    if(structureInInput == undefined)
                        Toast.error("Erro ao carregar os detalhes da entrada. Verifique o nome ou recrie o evento.")
                }
            }
            
            if (!ObjectUtils.equals({}, structureInInput)) {
                this.getDetailsAndFieldsBySelectedInput({
                    structureInInput,
                    stepInputs,
                    structureInputOptions: stepInputs
                    .filter(option => stepStructure.codInterfaceEvent !== option.codInterfaceEvent)
                    .map(option => {
                        return {
                            label: option.output,
                            value: option.codStructure,
                            codStructureDetail: option.idStructureDetail
                        }
                    })
                })
            } else {
                this.setState({
                    stepInputs,
                    structureInputOptions: 
                    stepInputs
                    .filter(option => stepStructure.codInterfaceEvent !== option.codInterfaceEvent)
                    .map(option => {
                        return {
                            label: option.output,
                            value: option.codStructure,
                            codStructureDetail: option.idStructureDetail
                        }
                    })
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { selectedNode, selectedNodeId } = this.state

        if(prevState.stepStructure !== this.props.stepStructure){
            selectedNode = undefined
            selectedNodeId = undefined
            this.setState({selectedNode, selectedNodeId})
            this.handleDidMountDetails(this.props.stepStructure)
            this.handleChangeTab(0,0)
        }
    }

    updateFromTree = newState => {
        let state = { ...this.state };
        
        if (newState.treeData) {
            state.treeData = newState.treeData;
        } else {
            state.newEvent = true
        }

        state.selectedNode = newState.selectedNode;
        state.selectedNodeId = newState.selectedNodeId;

        if (newState.structureInInput) {
            state.structureInInput = newState.structureInInput;
            state.stepStructure.structureInInput = newState.structureInInput;
            state.codStructureToGetDetailsExtraValues = newState.structureInInput.codStructure;
        }

        if (state.selectedNode
            && state.structureInInput) {
            state.selectedInputFields = state.detailsFields.filter(detailField =>
                state.selectedNode.node.idDetailInput === detailField.id
            );
        } else {
            delete state.selectedInputFields;
        }

        if (newState.structureInInput) {
            this.props.setTreeData(state.treeData)
            this.getDetailsAndFieldsBySelectedInput(state)
        } else {
            this.props.setTreeData(state.treeData)
            this.setState(state);
        }

        let {
            wizardState,
            structureIn
        } = this.state;
        let { stepStructure } = this.props
        var treeData = state.treeData

        const treeNodes = getFlatDataFromTree({
            treeData,
            getNodeKey: ({ treeIndex }) => {
                return treeIndex;
            },
            ignoreCollapsed: false
        })

        let newDetails = [];

        treeNodes.forEach(node => {
            const parentId = node.parentNode ? node.path[node.path.length - 2] + 1 : 0,
            codStructureDetailAndIndPosition = node.treeIndex + 1,
            title = node.node.title;

            let detail = stepStructure.structureDetails.find(detail => {
                if (node.node.idStructureDetail) {
                    return detail.idStructureDetail === node.node.idStructureDetail
                }
            })

            if (!detail) {
                detail = {
                    codStructureDetail: codStructureDetailAndIndPosition,
                    indPosition: codStructureDetailAndIndPosition,
                    codMaster: parentId,
                    fields: node.node.fields,
                    structureExtras: node.node.detailExtras,
                    idDetailInput: node.node.idDetailInput,
                    structureExtras: node.node.detailExtras,
                    codInstance: Session().codInstance,
                    title
                }
            } else {
                detail.codMaster = parentId
                detail.codStructureDetail = codStructureDetailAndIndPosition;
                detail.indPosition = codStructureDetailAndIndPosition;
                detail.title = title;
                detail.fields = node.node.fields;
                detail.structureExtras = node.node.detailExtras;
            }

            if (structureIn) {
                if (stepStructure.structureInInput) {
                    detail.input = stepStructure.structureInInput.output;
                }
            } else {
                detail.output = stepStructure.desOutputDetails || detail.output;
            }
            
            newDetails.push(detail);
        });

        stepStructure.structureDetails.filter((detail) => detail.idStructureDetail).forEach(
            (detail) => {
                if (!newDetails.find(nd => nd.idStructureDetail === detail.idStructureDetail)) {
                    wizardState.event.deletedDetails.push(detail.idStructureDetail);

                    if(detail.structureExtras != undefined && detail.structureExtras.length > 0){
                        detail.structureExtras.map(extra => {
                            wizardState.event.deletedExtras.push(extra.codStructureExtraValues)
                        })
                    }
                }
            }
        );

        stepStructure.structureDetails = newDetails;

        this.setState({stepStructure})
    }

    getDetailsAndFieldsBySelectedInput = newState => {
        WizardService
            .GetDetailsAndInputsByCodStructure(newState.structureInInput.codStructure)
            .then(({
                detailsFields = [],
                structureDetails = []
            }) => {
                var details = []
                var newTreeData = []

                structureDetails.sort((o1, o2) => o2.indPosition - o1.indPosition)
                structureDetails.forEach(e => {
                    e.children = []
                    e.codStructureDetail = e.idStructureDetail,
                        e.idDetailInput = e.idStructureDetail
                })

                structureDetails.forEach(e => {
                    if (e.codMaster) {
                        let parent = structureDetails.find(detail =>
                            detail.indPosition === e.codMaster
                        )
                        parent.children.push(e)
                        parent.children.sort((o1, o2) => o1.indPosition - o2.indPosition)
                    }
                })

                structureDetails
                    .sort((o1, o2) => o1.indPosition - o2.indPosition)
                    .forEach(e => { e.expanded = true });

                structureDetails
                    .filter(detail => !detail.codMaster)
                    .forEach(detail => {
                        details.push({
                            codStructureDetail: detail.idStructureDetail,
                            title: detail.title,
                            ...detail
                        })
                    })

                details.forEach(detail => {
                    newTreeData = newTreeData.concat([detail]);
                })

                if (newTreeData.length) {
                    newTreeData[0].firstSelectAfterInputChange = true;
                }

                newState.newEvent ? newState.treeData = newTreeData : null

                newState.detailsFields = detailsFields;
                newState.isLoading = false

                this.setState(newState, () => this.getDetailsExtraValues())
            })
    }

    updateFromTabs = selectedNode => this.setState({ selectedNode })

    setChangedDetail(action, sourceDetail, newPath) {
        let { wizardState } = this.state; 

        if (sourceDetail.idStructureDetail) {
            let changedDetail = wizardState.event.changedDetails[sourceDetail.path];
            if (!changedDetail) {
                changedDetail = {};
                wizardState.event.changedDetails[sourceDetail.path] = changedDetail;
            }          
            if (newPath === undefined) {
                if (sourceDetail.path != undefined) {
                    let parts = sourceDetail.path.split(".");
                    if(changedDetail.changes)
                        parts = wizardState.event.changedDetails[sourceDetail.path].changes.newPath.split(".")

                    let name1 = parts.slice(0, -1).join(".");   
                    newPath = name1 + '.' + sourceDetail.title;
                }
            } else 
                newPath = newPath + '.' + sourceDetail.title;

            changedDetail.action = action;
            changedDetail.changes = {
                title: sourceDetail.title,
                codStructureDetail: sourceDetail.codStructureDetail,
                codMaster: sourceDetail.codMaster,
                indPosition: sourceDetail.indPosition,
                newPath: newPath
            };
        }
        if(sourceDetail.children){
            for (let i = 0; i < sourceDetail.children.length; i++) {
                this.setChangedDetail('CHANGED',sourceDetail.children[i],newPath)
            }
        }
    }

    setChangedField(action, sourceField) {
        let { wizardState } = this.state;

        if (sourceField.codField) {
            let changedField = wizardState.event.changedFields[sourceField.path];
            if (!changedField) {
                changedField = {};
                wizardState.event.changedFields[sourceField.path] = changedField;
            }

            changedField.action = action;
            changedField.changes = {
                desField: sourceField.desField,
                indPosition: sourceField.indPosition,
                fieldAttributes: sourceField.fieldAttributes.map(attribute => {
                    return {
                        codAttribute: attribute.codAttribute,
                        desValue: attribute.desValue,
                        desLabel: attribute.desLabel,
                        indPosition: attribute.indPosition
                    }
                })
            };
        }
    }

    componentDidMount = _ => {
        if(!this.props.isStepAuxiliary) {
            this.handleDidMountDetails(this.props.stepStructure)
        }
    }

    handleDidMountDetails = e => {
        let { stepStructure, treeData, attributes, structureModel, isLoading } = this.state;
        stepStructure = e

        if (stepStructure.structureDetails.length) {
            let detailWithModel = stepStructure.structureDetails.find(detail =>
                detail.output && detail.idStructureDetail
            );

            if (detailWithModel) {
                structureModel = detailWithModel.output;
            }

            let orderedDetails = stepStructure.structureDetails.sort(
                (s1, s2) => { return s1.codMaster - s2.codMaster }
            ).map(detail => {
                if (!detail.path) {
                    detail.path = detail.title;
                }

                return {
                    ...detail,
                    expanded: true,
                    children: [],
                    codInstance: Session().codInstance,
                    detailExtras: detail.structureExtras
                }
            })

            orderedDetails.forEach(detail => {
                if (detail.codMaster > 0) {
                    let parentDetail = orderedDetails.find(det => {
                        if (detail.codMaster === det.codStructureDetail) {
                            detail.path = det.path + "." + detail.path

                            return det;
                        }
                    });

                    parentDetail.children.push(detail);
                }

                (detail.fields || []).map(field => {
                    if (!field.path) {
                        let fieldName = field.fieldAttributes.find(attribute => attribute.codAttribute == attributes.to);
                        if (!fieldName) {
                            fieldName = field.fieldAttributes.find(attribute => attribute.codAttribute == attributes.from);
                        }

                        field.path = detail.path + "." + (fieldName ? fieldName.desValue : "")
                    }
                });
            })

            treeData = orderedDetails.filter(detail => detail.codMaster === 0)
            this.props.setTreeData(treeData)
            this.props.setStepStructure(stepStructure)
            this.setState({
                treeData,
                structureModel,
                stepStructure
            })
        } else {
            isLoading = false
            if(treeData.length) {
                treeData = []
            }
            this.setState({isLoading, stepStructure, treeData})
        }
    }

    handleChangeTab = (_, selectedTab) => {
        if (selectedTab === this.state.selectedTab) {
            return;
        }

        this.setState({ selectedTab });
    }

    renderTabs = () => {
        const { tabs } = this.state
        return tabs.map(tab => (
            <Tab key={tab.label} label={tab.label} />
        ))
    }

    renderContent = selectedTab => {
        const {
            attributes,
            structureIn,
            stepStructure,
            stepProperties,
            stepAttributes,
            wizardState,
            structureInInput,
            structureInputOptions,
            selectedInputFields,
            stepInputs,
            tabs,
            stepExtras, 
            attributeGroupsDetails,
            selectedNode,
            selectedNodeId,
            treeData
        } = this.state

        if (tabs[selectedTab].label === Translate("structure_detail")) {
            return (
                <Fragment>
                    <Grid2 container size={15} spacing={1}>
                        <Grid2 container size={5}>
                            <WizardStructureDetailsTree
                                structureIn={structureIn}
                                stepStructure={stepStructure}
                                stepProperties={stepProperties}
                                structureInputOptions={structureInputOptions}
                                structureInInput={structureInInput}
                                event={wizardState.event}
                                stepInputs={stepInputs}
                                treeData={treeData}
                                treeHeight={'calc(100vh - 690px)'}
                                update={this.updateFromTree.bind(this)}
                                selectedNode={selectedNode}
                                selectedNodeId={selectedNodeId}
                                setChangedDetail={this.setChangedDetail.bind(this)}
                                renderEvent={this.props.renderEvent}/>
                        </Grid2>
                        <Grid2 container size={7}>
                            <WizardStructureDetailsTabs
                                attributes={attributes}
                                detailsExtras={stepExtras.detailsExtras}
                                detailsExtrasAttributes={stepAttributes.detailsExtrasAttributes}
                                selectedNode={selectedNode}
                                wizardState={wizardState}
                                stepStructure={stepStructure}
                                stepProperties={stepProperties}
                                attributeGroups={attributeGroupsDetails}
                                structureInInput={structureInInput}
                                structureInputOptions={structureInputOptions}
                                selectedInputFields={selectedInputFields}
                                update={this.updateFromTabs.bind(this)}
                                setChangedField={this.setChangedField.bind(this)}/>
                        </Grid2>
                    </Grid2>
                </Fragment>
            )
        } else if (tabs[selectedTab].label === Translate("structure_extra")) {
            if (stepAttributes.structureExtrasAttributes) {
                return (
                    <Fragment>
                        <WizardStructureExtras 
                            structureExtras={stepExtras.structureExtras}
                            structureExtrasAttributes={stepAttributes.structureExtrasAttributes}
                            wizardState={wizardState}
                            stepStructure={stepStructure}
                            stepProperties={stepProperties}
                            updateStepStructure={this.updateStepStructure}/>
                    </Fragment>
                )
            } else {
                return null
            }
        }
    }

    disabledStep = _ => {
        const {
            wizardState
        } = this.state;

        const isDisabled = false;
        this.props.setStepStatus(wizardState.currentStep, !isDisabled)

        return isDisabled;
    }

    updateStepStructure = stepStructure => this.setState({ stepStructure });

    getDetailsExtraValues = () => {
        const { codStructureToGetDetailsExtraValues } = this.state

        if(codStructureToGetDetailsExtraValues != undefined){
            WizardService.GetDetailsExtraValues(codStructureToGetDetailsExtraValues)
            .then(({structureDetailsExtraValues = new Array}) => { return structureDetailsExtraValues })
            .then(structureDetailsExtraValues => {
                const { treeData } = this.state
                const treeNodes = getFlatDataFromTree({treeData, getNodeKey: ({ treeIndex }) => {return treeIndex}, ignoreCollapsed: false})
                
                treeNodes.forEach(node => {
                    let codStructureDetailAndIndPosition = node.treeIndex + 1
                    let detailExtras = this.getExtrasFromInputStructureDetailsExtras(codStructureDetailAndIndPosition, structureDetailsExtraValues)

                    node.node.detailExtras = detailExtras
                    node.node.structureExtras = detailExtras
                })

                return treeData
            })
            .then(treeData => this.setState({ treeData }))
        }
    }

    getExtrasFromInputStructureDetailsExtras = (indPosition, inputStructureDetailsExtras) => {
        const { wizardState } = this.state

        let structureDetailsExtras = inputStructureDetailsExtras
        .filter(structureDetailExtra => {
            if(structureDetailExtra.codStructureDetail == indPosition) return structureDetailExtra
        }).map(structureDetailExtra => {
            if(wizardState.isEdit === true) return {
                "codInstance": structureDetailExtra.codInstance,
                "codStructureDetail": structureDetailExtra.codStructureDetail,
                "codAttribute": structureDetailExtra.codAttribute,
                "codStructureExtraValues": wizardState.event.deletedExtras.length > 0 ? 0 : structureDetailExtra.codStructureExtraValues,
                "codStructure": structureDetailExtra.codStructure,
                "codStructureExtra": structureDetailExtra.codStructureExtra,
                "value": structureDetailExtra.value 
            } 
            else return {
                "codAttribute": structureDetailExtra.codAttribute,
                "codStructureExtraValues": 0,
                "codStructure": 0,
                "codStructureExtra": structureDetailExtra.codStructureExtra,
                "value": structureDetailExtra.value
            }
        }).filter(extra => {
            if(extra.codStructureExtra != 74) return extra
        })

        return structureDetailsExtras
    }

    render() {
        const {
            selectedTab,
            isLoading,
            structureIn
        } = this.state

        if (isLoading && structureIn) return <Loading/>

        return (
            <Fragment>
                <StyledTabs
                    value={selectedTab}
                    onChange={this.handleChangeTab.bind(this)}>
                    {this.renderTabs()}
                </StyledTabs>
                <StyledGrid size={15} container>
                    {this.renderContent(selectedTab)}
                </StyledGrid>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setWizardState: wizardState => dispatch(
            setWizardState(wizardState)
        ),
        setStepStatus: (step, complete) => dispatch(setStepStatus(step, complete)),
        setWizardStructureArray: structuresArray => dispatch(setWizardStructureArray(structuresArray)),
        setStepStructure: stepStructure => dispatch(setStepStructure(stepStructure)),
        setTreeData: treeData => dispatch(setTreeData(treeData))
    }
}

const mapStateToProps = store => {
    const [inputs] = inputSelector.getInputs(store);

    return {
        inputs,
        wizardState: wizardStore.getWizardState(store),
        attributes: wizardStore.getAttributes(store),
        selectedStructure: wizardStore.getSelectedStructure(store),
        isStepAuxiliary: wizardStore.getStepAuxiliary(store),
        stepStructure: wizardStore.getStepStructure(store),
        treeData: wizardStore.getTreeData(store)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardStructureTabs);