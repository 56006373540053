import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { setStepStatus } from "../../../store/app-state/actions";
import * as inputSelector from "../../../store/interfaces/reducer";
import * as wizardStore from "../../../store/wizard/wizard-store-reducer";
import { setWizardState } from "../../../store/wizard/wizard-store-actions";
import BanksService from "../../bank-registration/bank-accounts-service";
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import Loading from "../../../componentsUI/loading"
import ConectorSelect from "../../../componentsUI/conectorSelect"
import { Grid2 } from "@mui/material"

class BankEventStructure extends Component {
    constructor(props) {
        super(props);

        var { wizardState } = props;

        this.state = {
            loading: true,
            bankAccountsInputOptions: undefined,
            inputBankAccountOptionSelected: undefined,
            listboxOptionSelected: 1, 
            structures: wizardState.event.structures,
            codBank: wizardState.event.codBank,
            listboxOptions: [
                { value: 1, label: 'D0' },
                { value: 2, label: 'D-1' },
                { value: 3, label: 'D-2' }
            ],
            wizardState: this.props,
            ...props
        };
    }

    componentWillMount() {
        const { codBank } = this.state;

        if (codBank == undefined) this.getBankAccountToSetCodBank();
        this.getBankAccountsToInputOptions();
    }

    getBankAccountToSetCodBank() {
        const { wizardState } = this.state
        
        let structures = wizardState.event.structures
        let idAccount = structures[0].bankStructures.idBankAccount
    
        BanksService.GetBankAccount(idAccount)
            .then(account => {
                // Verificar se a resposta contém a propriedade cod_bank
                if (account && account.cod_bank) {
                    this.setState({ codBank: account.cod_bank });
                } else {
                    console.error('Resposta inválida do servidor: ', account);
                }
            })
            .catch(error => {
                console.error('Erro ao buscar a conta bancária: ', error);
            })
            .finally(() => this.getBankAccountsToInputOptions());
    }

    getBankAccountsToInputOptions() {
        const { codBank, wizardState, listboxOptions } = this.state;
        let structures = wizardState.event.structures
        const launchPeriod = (structures.length > 0) ? structures[0].bankStructures.launchPeriod : 1;
    
        BanksService
        .GetBankAccounts(codBank)
        .then(bankAccounts => {
            // Verificar se accounts é um array
            if (!Array.isArray(bankAccounts)) bankAccounts = [bankAccounts];
    
            let bankAccountsInputOptions = bankAccounts.map(bankAccount => {
                    let inputAccountValue = { value: bankAccount.id_account };
    
                    if (bankAccount.account_digit === undefined || bankAccount.account_digit === '')
                        inputAccountValue.label = `C/C ${bankAccount.account}`;
                    else
                        inputAccountValue.label = `C/C ${bankAccount.account} - ${bankAccount.account_digit}`;
    
                    if (bankAccount.bank_branch_digit === undefined || bankAccount.bank_branch_digit === '')
                        inputAccountValue.label += ` AG ${bankAccount.bank_branch}`;
                    else
                        inputAccountValue.label += ` AG ${bankAccount.bank_branch} - ${bankAccount.bank_branch_digit}`;
    
                    return inputAccountValue;
            });
    
            // Encontrar a opção correspondente ao LaunchPeriod
            const listboxOptionSelected = listboxOptions.find(option => {
                if(launchPeriod == option.value) return option
            })

            if(wizardState.renderedWizardContent === false) {
                this.props.setWizardState({ renderedWizardContent: true })
            }
    
            // Atualizar o estado
            this.setState({ bankAccountsInputOptions, listboxOptionSelected, loading: false }, () => this.getBankAccountRegistered());
        })
        .catch(error => {
            console.error('Erro ao buscar as contas bancárias:', error);
            this.setState({ loading: false });
        })
    }

    getBankAccountRegistered() {
        const { bankAccountsInputOptions, wizardState } = this.state;
        let structures = wizardState.event.structures
        let inputBankAccountOptionSelected = bankAccountsInputOptions[0]
        let idAccount = structures.length > 0 ? structures[0].bankStructures.idBankAccount : undefined

        if(idAccount != undefined){
            inputBankAccountOptionSelected = bankAccountsInputOptions.find(bankAccount => {
                return bankAccount.value == idAccount
            })
        }

        this.setState({ inputBankAccountOptionSelected }, () => this.setOptionSelectedInWizardState())
    }

    setBankAccountOptionSelected(selectedOption) {
        this.setState({ inputBankAccountOptionSelected: selectedOption }, () => {
            this.setOptionSelectedInWizardState();
        });
    }

    setLaunchPeriodOptionSelected(selectedOption) {
        this.setState({ listboxOptionSelected: selectedOption }, () => {
            this.setOptionSelectedInWizardState();
        });
    }

    setOptionSelectedInWizardState() {
        const { inputBankAccountOptionSelected, listboxOptionSelected, wizardState } = this.state;
        let structures = wizardState.event.structures

        if (structures != undefined && structures.length != 0) {
            if(structures[0].bankStructures != undefined) {               
                structures[0].bankStructures.idBankAccount = inputBankAccountOptionSelected.value
                structures[0].bankStructures.launchPeriod = listboxOptionSelected.value  
            }
        } else structures.push({
            codStructure: 0,
            codInstance: wizardState.event.codInstance,
            codEventProperty: 228,
            codConnectionAction: wizardState.event.codConnectionAction,
            codConnectionType: wizardState.event.codConnectionType,
            isMain: true,
            bankStructures: {
                idBankAccount: inputBankAccountOptionSelected.value,
                launchPeriod: listboxOptionSelected.value
            }
        })

        this.setState({wizardState})
    }

    render() {
        const {
            loading,
            inputBankAccountOptionSelected,
            bankAccountsInputOptions,
            listboxOptionSelected,
            listboxOptions
        } = this.state;

        if (loading) return <Loading/>

        return (
            <Fragment>
                    <Grid2 container spacing={1} alignItems="center">
                        <Grid2 size={8}>
                            <ConectorSelect
                            label="SelectBankAccount"
                            placeholder="SelectBankAccountOption"
                            onChange={selectedOption => this.setBankAccountOptionSelected(selectedOption)}
                            options={bankAccountsInputOptions}
                            value={inputBankAccountOptionSelected}
                            width="100%"/>
                        </Grid2>
                        <Grid2 size={4}>
                            <ConectorSelect
                            label="SelectReleasePeriod"
                            placeholder="LaunchPeriod"
                            onChange={selectedOption => this.setLaunchPeriodOptionSelected(selectedOption)}
                            options={listboxOptions}
                            value={listboxOptionSelected}
                            width="100%"/>
                        </Grid2>
                    </Grid2>
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setWizardState: wizardState => dispatch(
            setWizardState(wizardState)
        ),
        setStepStatus: (step, complete) => dispatch(setStepStatus(step, complete)),
    };
}

const mapStateToProps = store => {
    const [inputs] = inputSelector.getInputs(store)

    return {
        inputs,
        wizardState: wizardStore.getWizardState(store),
        attributes: wizardStore.getAttributes(store)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BankEventStructure)