import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import moment from "moment"
import Translate from '../../i18n/translate'
import Session from "../../utils/session-info"
import { setBreadcrumb } from "../../store/app-state/actions"
import PermissionsHistoryService from "./permissions-history-service"
import CompaniesService from "../companies/companies-service"
import Loading from "../../componentsUI/loading"
import ConectorTable from "../../componentsUI/conectorTable"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import ConectorSelect from "../../componentsUI/conectorSelect"

class PermissionsHistory extends Component {
    constructor(props) {
        super(props)

        props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "permissions_history"
        }])

        this.state = {
            loading: true,
            permissionsHistory: [],
            companiesList: [],
            selectedCompanyID: Session().codCompany,
            gridOptions: {
                multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                sortField: "DateChange",
                sortType: "desc",
                columns: [
                    {
                        title: "change_date",
                        field: "DateChange"
                    },
                    {
                        title: "change_type",
                        field: "ChangeDetails.changeType"
                    },
                    {
                        title: "company_name",
                        field: "companyName"
                    },
                    {
                        title: "instance_name",
                        field: "instanceName"
                    },
                    {
                        title: "modified_by",
                        field: "ChangeDetails.changedByNameUser"
                    },
                    {
                        title: "modified_user",
                        field: "ChangeDetails.changedNameUser"
                    },
                    {
                        title: "current_profile",
                        field: "ChangeDetails.currentNameProfile"
                    },
                    {
                        title: "previous_profile",
                        field: "ChangeDetails.previousNameProfile"
                    }
                ]
            }
        }
    }

    componentDidMount = () => this.getCompaniesAndPermissionsHistory(this.state.selectedCompanyID)

    getCompaniesAndPermissionsHistory = selectedCompanyID => {
        Promise.all([
            PermissionsHistoryService.GetPermissionsHistory(selectedCompanyID)
                .then(permissionsHistory => this.formatPermissionsChangesInfo(permissionsHistory)),
            PermissionsHistoryService.GetAllCompaniesToPermissionsHistory()
        ]).then(([permissionsHistory, companiesList]) => {
            this.setState({ permissionsHistory, companiesList }, () => {
                this.setState({ loading: false })
            })
        })
    }

    formatPermissionsChangesInfo = permissionsHistory => {
        if (permissionsHistory == null || permissionsHistory == undefined) return []

        return permissionsHistory.map(permission => {
            return {
                ...permission,
                DateChange: moment(permission.DateChange).format("DD/MM/YYYY HH:mm:ss"),
                ChangeDetails: {
                    ...permission.ChangeDetails,
                    changeType: this.translateChangeType(permission.ChangeDetails.changeType),
                    changedByNameUser: this.concatCodeName(permission.ChangeDetails.changedByCodUser, permission.ChangeDetails.changedByNameUser),
                    changedNameUser: this.concatCodeName(permission.ChangeDetails.changedCodUser, permission.ChangeDetails.changedNameUser),
                    currentNameProfile: this.concatCodeName(permission.ChangeDetails.currentCodProfile, permission.ChangeDetails.currentNameProfile),
                    previousNameProfile: this.concatCodeName(permission.ChangeDetails.previousCodProfile, permission.ChangeDetails.previousNameProfile)
                }
            }
        })
    }

    concatCodeName = (code, name) => code == 0 ? null : `${code} - ${name}`

    translateChangeType = changeType => {
        switch (changeType) {
            case "Delete":
                return Session().language == "pt-BR" ? "Deletado" : "Deleted"
            case "Update":
                return Session().language == "pt-BR" ? "Alterado" : "Updated"
            case "Register":
                return Session().language == "pt-BR" ? "Registrado" : "Registered"
        }
    }

    getPermissionsHistoryByCodCompany = codCompany => {
        this.setState({ loading: true }, () => {
            PermissionsHistoryService.GetPermissionsHistory(codCompany)
                .then(permissionsHistory => this.formatPermissionsChangesInfo(permissionsHistory))
                .then(permissionsHistory => {
                    this.setState({ permissionsHistory, selectedCompanyID: codCompany, loading: false })
                })
        })
    }

    renderCompaniesListFilter = (companiesList, selectedCompanyID) => {
        return <ConectorSelect
            label={'company'}
            help={'company_select'}
            onChange={selectedObj => this.getPermissionsHistoryByCodCompany(selectedObj.id)}
            options={companiesList}
            value={companiesList.find(company => company.id === selectedCompanyID) || null} />
    }

    renderDataTable = (gridOptions, permissionsHistory) => {
        return <ConectorTable gridOptions={gridOptions} dataSource={permissionsHistory} />
    }

    render = () => {
        const {
            loading,
            gridOptions,
            permissionsHistory,
            companiesList,
            selectedCompanyID
        } = this.state

        if (loading) return <Loading />
        else return (
            <StyledPaper>
                <InternalPageTitle title={Translate("permissions_history")} />
                {this.renderCompaniesListFilter(companiesList, selectedCompanyID)}
                {this.renderDataTable(gridOptions, permissionsHistory)}
            </StyledPaper>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(null, mapDispatchToProps)(PermissionsHistory);