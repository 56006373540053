import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import Translate from "../../i18n/translate"
import Toast from "../../components/toast/toast"
import { setBreadcrumb } from "../../store/app-state/actions"
import * as appStateReducer from "../../store/app-state/reducer"
import AccessReleasesService from "./access-releases-service"
import ConectorTable from "../../componentsUI/conectorTable"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"
import Loading from "../../componentsUI/loading"

class AccessReleases extends Component {
	constructor(props) {
		super(props)

		props.setBreadcrumb([{
			label: "registrations"
		}, {
			label: "accessrelease"
		}])

		this.state = {
			loading: true,
			accessReleasesDataSource: [],
			dialog: { open: false },
			gridOptions: {
				multiSelect: false,
				hideBtnNew: true,
				hideBtnEdit: true,
				hideBtnDelete: true,
				hideBtnCopy: true,
				columns: [{
					title: "name",
					field: "User.desName"
				}, {
					title: "profile",
					field: "profileDescription"
				}, {
					title: "default",
					field: "IsDefault",
					cellFilter: "boolean"
				}]
			}
		}
	}

	componentDidMount = () => this.getUserAccessAndProfiles()

	setLoadingPage = loading => this.setState({ loading })

	redirectToHome = () => window.location.replace('#/')

	redirectToAddUserAccessReleasse = () => window.location.replace('#/accessrelease/add')

	redirectToEditUserAccessReleasse = (codUser, codProfile) => window.location.replace(`#/accessrelease/${codUser}&${codProfile}`)

	getUserAccessAndProfiles() {
		this.setLoadingPage(true)

		Promise.all([
			AccessReleasesService.GetAccessReleases(),
			AccessReleasesService.GetProfiles(),
			AccessReleasesService.GetUserPermissionAccessLevel()
		])
			.then(([accessReleasesData, profilesList, userAccessLevel]) => {
				let { gridOptions } = this.state

				if ([2, 4].includes(userAccessLevel.accessLevel)) {
					gridOptions = this.getGridOptionsUserManagementMode(gridOptions)
				}

				return {
					gridOptions,
					accessReleasesDataSource: this.getDataSourceProfileDescriptions(accessReleasesData, profilesList),
					profilesList
				}
			})
			.then(state => this.setState({ ...state }, () => this.setLoadingPage(false)))
			.catch(error => {
				Toast.error(Translate(error.message))
				this.redirectToHome()
			})
	}

	getGridOptionsUserManagementMode = gridOptions => {
		const gridOptionsUserManagementMode = {
			...gridOptions,
			multiSelect: true,
			hideBtnNew: false,
			hideBtnEdit: false,
			hideBtnDelete: false
		}

		return gridOptionsUserManagementMode
	}

	getDataSourceProfileDescriptions = (accessReleasesData, profilesList) => {
		let accessReleases = accessReleasesData;
		let profiles = profilesList

		if (profiles.length) {
			accessReleases.forEach(accessRelease => {
				let profile = profiles.find(profile => profile.codProfile === accessRelease.codProfile)
				if (profile != undefined) accessRelease.profileDescription = profile.description
			})
		}

		return accessReleases
	}

	editUserAccessReleasse([row]) {
		if (!row) return
		if (row.IsDefault) return Toast.error(Translate("not_possible_edit_company"))

		this.redirectToEditUserAccessReleasse(row.codUser, row.codProfile)
	}

	openDeleteDialog(userAccess) {
		this.setState({
			dialog: {
				type: "danger",
				open: true,
				message: "remove_records",
				title: "exclusion_confirmation",
				onClose: () => {
					let userAccessToDelete = this.extractInfosOfUserFromAccessList(userAccess)
					this.deleteAccessReleases(userAccessToDelete)
				}
			}
		})
	}

	extractInfosOfUserFromAccessList(userAccess) {
		return userAccess.map(item => ({
			CodInstance: item.codInstance,
			CodCompany: item.codCompany,
			CodProfile: item.codProfile,
			CodUser: item.codUser
		}))
	}

	deleteAccessReleases = listOfUserAccessToDelete => {
		AccessReleasesService.DeleteAccessReleases(listOfUserAccessToDelete)
			.then(res => res.json())
			.then(({ msg }) => {
				if (msg) throw new Error(msg)
				else return msg
			})
			.then(() => {
				Toast.success("records_success_removed")
				this.closeDeleteDialog()
			})
			.catch(error => {
				Toast.error(Translate(error.message))
				this.closeDeleteDialog()
			})
	}

	closeDeleteDialog = () => this.setState({ dialog: { open: false } }, () => this.getUserAccessAndProfiles())

	renderUserAccessReleasesTable = () => {
		const {
			gridOptions,
			dialog,
			accessReleasesDataSource
		} = this.state

		return (
			<Fragment>
				<ConectorTable
					gridTitle={"accessrelease"}
					gridBtn={"set_up_new_access"}
					gridNewItem={this.redirectToAddUserAccessReleasse.bind(this)}
					gridEditItem={this.editUserAccessReleasse.bind(this)}
					gridDeleteItem={this.openDeleteDialog.bind(this)}
					gridOptions={gridOptions}
					dataSource={accessReleasesDataSource}>
				</ConectorTable>
				<ConectorDialogConfirmation dialogOptions={dialog} />
			</Fragment>
		)
	}

	render() {
		const { loading } = this.state

		if (loading) return <Loading/>
		else return this.renderUserAccessReleasesTable() 
	}
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessReleases)