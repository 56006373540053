import { display, styled } from '@mui/system';
import { Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme, enable  }) => ({
  width: '100%',
  display: enable ? '' : 'none',
  '& button': {
    color: '#6F8296',
    fontSize: '0.75rem',
    backgroundColor: '#f4f4f4',
    borderTop: '1px solid #B8C6D6',
    borderLeft: '1px solid #B8C6D6',
    borderRight: '1px solid #B8C6D6',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    opacity: 0.3,
    '&:hover, &.Mui-selected': {
      backgroundColor: '#f4f4f4',
      borderTop: '1px solid #B8C6D6',
      borderLeft: '1px solid #B8C6D6',
      borderRight: '1px solid #B8C6D6',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      opacity: 1,
    },
  },
  '.MuiTabs-indicator': {
    height: 0,
  },
}));
