import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Session from '../../utils/session-info'
import Translate from '../../i18n/translate'
import Toast from '../../components/toast/toast'
import ConnectionsService from './connections-service'
import { setBreadcrumb, setSteps } from '../../store/app-state/actions'
import Step1 from './steps/connections-details-step-1'
import Step2 from './steps/connections-details-step-2'
import Loading from "../../componentsUI/loading"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import ConectorStepper from '../../componentsUI/stepper'
import { ContainerDiv, DivButtons } from "../../componentsUI/styledComponents/styledConnectionsCopyModal"
import { Grid2 } from "@mui/material"

class ConnectionsDetalhe extends Component {
    constructor(props) {
        super(props)

        let connectionID = window.location.hash.substring(14)

        props.setBreadcrumb([
            {
                label: 'integrations'
            },
            {
                label: 'connections',
                url: '#/connections'
            },
            {
                label: connectionID !== 'add'
                    ? 'edit_connection'
                    : 'add_connection'
            }
        ]);

        const { validations, attributeTypes, currentStep, connection } = props;

        this.state = {
            connectionID: connectionID,
            validations,
            attributeTypes,
            currentStep,
            connection: { ...connection, deletedHeaderParams: [] }
        }
    }

    refresh({ connection, stepDisabled }) {
        this.setState({ connection, stepDisabled });
    }

    componentWillMount() {
        const { connectionID } = this.state;

        ConnectionsService
            .GetAttributeTypes()
            .then(attributeTypes => {
                this.setState({ attributeTypes });
            });

        if (connectionID === "add") {
            this.setState({ isReady: true })
        } else this.getConnection(connectionID)
    }

    getConnection = connectionID => {
        ConnectionsService
        .GetConnection(connectionID)
        .then(connection => {

            if (!connection.id) {
                Toast.error('Conexão não encontrada');
                this.cancel();
            }

            this.setState({
                connection,
                isReady: true
            });
        });
    }

    save() {
        this.setState({ validations: [] });

        var { connection } = this.state;

        if (connection.codConnectionAction === 10) {
            connection.codConnectionMode = 0;
        }

        if (!connection.id) {
            connection.codInstance = Session().codInstance;
            ConnectionsService
                .PostConnection(connection)
                .then(onSuccess.bind(this));
        } else {
            ConnectionsService
                .PutConnection(connection)
                .then(onSuccess.bind(this));
        }

        function onSuccess(res) {
            if (res.ok) {
                Toast.success('record_success_saved');
                window.location.replace('#/connections')
            } else {
                if(res.status == 401) Toast.error("userWithoutPermissionInstance")
                else Toast.error('error_saving_record');
            }
        }
    }

    renderStep(step) {
        let steps = [Step1, Step2],
            { connection, validations, attributeTypes } = this.state;

        return React.createElement(steps[step - 1], {
            refresh: this.refresh.bind(this),
            connection,
            validations,
            attributeTypes
        });
    }

    forward = _ => {
        let {
            description,
            codConnectionType,
            codConnectionMode
        } = this.state.connection,
            currentStep = this.state,
            validations = [];

        if (currentStep === 1) {
            if (!description) {
                validations.push({
                    field: Translate('description'),
                    description: Translate('enter_description')
                });
            }
            if (!codConnectionType) {
                validations.push({
                    field: Translate('connection_type'),
                    description: Translate('enter_type')
                });
            } else if (!codConnectionMode) {
                validations.push({
                    field: Translate('connection_mode'),
                    description: Translate('enter_connection_mode')
                });
            }
        }

        this.setState({
            currentStep: validations.length ? 1 : 2,
            validations
        });
    }

    back = _ => {
        this.setState({ currentStep: 1 });
    }

    cancel = _ => {
        window.location.replace('#/connections')
    }

    handleStep = step => {
        this.setState({ currentStep: step })
    }

    render() {
        const {
            connectionID,
            isReady,
            currentStep,
            stepDisabled
        } = this.state

        this.props.setSteps([{
            label: 'connection_details',
            complete: !stepDisabled
        }, {
            label: 'connection_params',
            complete: !stepDisabled
        }]);

        if (!isReady) {
            return (<Loading />);
        }

        return (
            <Fragment>
                <InternalPageTitle title={
                    connectionID !== "add"
                        ? 'edit_connection'
                        : 'new_connection'
                }/>
                <StyledPaper>
                    <ConectorStepper
                        handleStep={this.handleStep}
                        currentStep={currentStep - 1}/>
                    <ContainerDiv>
                        {this.renderStep(currentStep)}
                    </ContainerDiv>
                    <Grid2 container justifyContent='space-between'>
                        <Grid2 container>
                            <StyledButton
                                className={currentStep === 1 ? 'hidden' : 'left'}
                                variant='contained'
                                onClick={this.back.bind(this)}>
                                {Translate('back')}
                            </StyledButton>
                        </Grid2>
                        <Grid2 container spacing={1}>
                            <StyledButton
                                variant='contained'
                                onClick={this.cancel.bind(this)}>
                                {Translate('cancel')}
                            </StyledButton>
                            <StyledButton
                                className={currentStep === 2 ? 'right' : 'hidden'}
                                disabled={stepDisabled}
                                variant='outlined'
                                onClick={this.save.bind(this)}>
                                {Translate('save')}
                            </StyledButton>
                            <StyledButton 
                                className={currentStep !== 1 ? 'hidden' : 'right'}
                                variant='outlined'
                                disabled={stepDisabled}
                                onClick={this.forward.bind(this)}>
                                {Translate('go_forward')}
                            </StyledButton>
                        </Grid2>
                    </Grid2>
                </StyledPaper>
            </Fragment>
        )
    }
}

ConnectionsDetalhe.defaultProps = {
    validations: [],
    attributeTypes: [],
    currentStep: 1,
    connection: {
        isActive: true
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
        setSteps: steps => dispatch(setSteps(steps))
    }
}

ConnectionsDetalhe = connect(null, mapDispatchToProps)(ConnectionsDetalhe);
export default ConnectionsDetalhe;