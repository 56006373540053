import React, { Component, Fragment } from 'react'
import WizardService from '../wizard-service'
import * as wizardStore from '../../../store/wizard/wizard-store-reducer'
import { setWizardState } from '../../../store/wizard/wizard-store-actions'
import { connect } from 'react-redux'
import { setStepStatus } from '../../../store/app-state/actions'
import ConectorCheckbox from "../../../componentsUI/checkBox"
import ConectorSelect from "../../../componentsUI/conectorSelect"
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import ConectorInputText from "../../../componentsUI/inputText"
import { Grid2 } from "@mui/material"

class GenericStepConnections extends Component {
    constructor(props) {
        super(props)

        props.setWizardState({ disabledStep: false })
        const codEventType = props.wizardState.event.codEvent;

        this.state = {
            availableConnectionsOptions: [],
            hasConnection: codEventType == 1 ? true : false,
            ...props.wizardState
        }
    }

    componentWillReceiveProps = props => {
        const { event } = this.state
        this.setState({ ...props.wizardState }, this.getConnectionsByConnectionType)
    }

    filterConnectionsByConnectionMode = (codConnectionMode, connections) => connections.filter(connection => {
        if (connection.codConnectionMode == codConnectionMode) return connection
    })

    getConnectionsByConnectionType = () => {
        const { event } = this.state
        let availableConnectionsOptions = new Array

        WizardService.GetConnectionsByConnectionType(event.codConnectionType)
            .then(availableConnections => {
                if (event.structures.length) {
                    let eventStructure = event.structures[0]
                    let codConnectionMode = event.instanceConnection ? event.instanceConnection.codConnectionMode : event.structures[0].codConnectionMode

                    if (eventStructure.codInstanceConnection) {
                        event.codInstanceConnection = eventStructure.codInstanceConnection;
                        event.instanceConnection = availableConnections.find(connection => eventStructure.codInstanceConnection === connection.id)
                    }

                    availableConnectionsOptions = this.filterConnectionsByConnectionMode(codConnectionMode, availableConnections)
                } else availableConnectionsOptions = availableConnections

                return { event, availableConnections, availableConnectionsOptions }
            })
            .then(eventAndAvailableConnections => {
                let { event, availableConnections, availableConnectionsOptions } = eventAndAvailableConnections

                availableConnectionsOptions = availableConnectionsOptions.map(connection => { return { label: connection.description, value: connection.id } })

                return { event, availableConnections, availableConnectionsOptions }
            })
            .then(eventAndAvailableConnections => {
                const { event, availableConnections, availableConnectionsOptions } = eventAndAvailableConnections
                this.setState({ event, availableConnections, availableConnectionsOptions })
            })
    }

    handleConnectionChange = e => {
        let { event, availableConnections } = this.state
        event.codInstanceConnection = e.value
        event.instanceConnection = availableConnections.find(connection =>
            e.value === connection.id
        );

        if (event.structures && event.structures.length) {
            event.structures.forEach(struc => struc.codInstanceConnection = e.value)
        }

        this.props.setWizardState({ event })
    }

    getValue = _ => {

        const { event, availableConnectionsOptions } = this.state

        if (!event.codInstanceConnection
            && (!event.structures || !event.structures.length)) {
            return;
        }

        return availableConnectionsOptions.find(connection => {
            if (connection.value === event.codInstanceConnection) {
                return connection;
            }

            if (event.structures[0] && connection.value === event.structures[0].codInstanceConnection) {
                return connection;
            }
        })
    }

    disabledStep = _ => {
        const { event, currentStep, hasConnection } = this.state;
        const isDisabled = hasConnection && !Boolean(event.codInstanceConnection);
        this.props.setStepStatus(currentStep, !isDisabled)
        return isDisabled;
    }

    onChangeInput = ({ target: { value, name } }) => {
        let { event } = this.state;
        event[name] = value;
        this.props.setWizardState({ event });
    }

    onChangeCheckBox = ({ target: { value, name } }) => {
        let { event } = this.state;
        event[name] = !(value === "true");
        this.props.setWizardState({ event });
    }

    render() {
        const { availableConnectionsOptions, disabledStep, event, hasConnection } = this.state

        const isDisabled = this.disabledStep();

        if (isDisabled !== disabledStep) this.props.setWizardState({ disabledStep: isDisabled })

        return (
            <Fragment>
                {
                    hasConnection ? (
                        <Grid2 container spacing={1}>
                            <Grid2 size={5}>
                                <ConectorSelect
                                    label="Conexão"
                                    help="Selecione uma conexão"
                                    placeholder="Selecione..."
                                    options={availableConnectionsOptions}
                                    onChange={this.handleConnectionChange}
                                    value={this.getValue()}
                                />
                            </Grid2>
                        </Grid2>
                    ) : null
                }
                <Grid2 container spacing={2}>
                    <Grid2 size={5}>
                        <ConectorInputText label='Descrição Evento'
                            help='Informe uma descrição para o evento'
                            placeholder='Selecione...'
                            name='desEventName'
                            value={event.desEventName}
                            onChange={this.onChangeInput}
                        />
                    </Grid2>
                    <Grid2>
                        <ConectorCheckbox
                            name="isCritical"
                            color="default"
                            value={event.isCritical}
                            onChange={this.onChangeCheckBox}
                            checked={event.isCritical}
                            label="Critico"
                        />
                    </Grid2>
                </Grid2>
            </Fragment>
        )
    }
}

const mapStateToProps = store => {
    const wizardState = wizardStore.getWizardState(store);
    return { wizardState }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setWizardState: newWizardState => dispatch(
            setWizardState(newWizardState)
        ),
        setStepStatus: (step, complete) => dispatch(setStepStatus(step, complete)),
    }
}

GenericStepConnections = connect(
    mapStateToProps,
    mapDispatchToProps
)(GenericStepConnections)
export default GenericStepConnections