import React, { Component } from "react"
import {
    addNodeUnderParent,
    removeNodeAtPath,
    getNodeAtPath
} from "@nosferatu500/react-sortable-tree"
import {
    MdEdit,
    MdDelete,
    MdFilterList,
    MdLowPriority,
    MdUndo
} from "react-icons/md"
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Tooltip,
    FormControl,
    FormControlLabel,
    Checkbox
} from "@mui/material"
import TransformUtil from "../transform-util"
import TransformConcat from "../tabs/transform-concat"
import Translate from "../../../../i18n/translate"
import ConectorSelect from "../../../../componentsUI/conectorSelect"
import ConectorInputText from "../../../../componentsUI/inputText"
import StyledButton from "../../../../componentsUI/styledComponents/styledButton"
import { StyledSortableTreeTransform } from "../../../../componentsUI/styledComponents/styledSortableTree"
import { StyledDialog, StyledIconButton } from "../../../../componentsUI/styledComponents/styledTransform"

const getNodeKey = ({ treeIndex }) => treeIndex;

class TransformCase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            modal: {
                opened: false,
                textInput: "",
                sortableMaxHeight: 0,
                fieldName: ""
            }
        };
    }

    componentWillMount = () => {
        const { fields } = this.state;

        this.setState({
            sortableMaxHeight: window.innerHeight - 70,
            fieldList: fields.map(field => {
                return {
                    value: field.path,
                    label: field.title
                }
            }),
            operatorList: [{
                value: "equal",
                label: Translate("equal")
            }, {
                value: "different",
                label: Translate("different")
            }, {
                value: "less_than",
                label: Translate("less_than")
            }, {
                value: "less_or_equal",
                label: Translate("less_or_equal")
            }, {
                value: "greater_than",
                label: Translate("greater_than")
            }, {
                value: "greater_than_or_equal",
                label: Translate("greater_than_or_equal")
            }, {
                value: "contains",
                label: Translate("contains")
            }],
            fieldTypeList: [{
                value: "text",
                label: Translate("string")
            }, {
                value: "field",
                label: Translate("field")
            }, {
                value: "concat",
                label: Translate("concat")}
            ]
        });

        window.addEventListener("resize", this.handleViewResize, false);
    }

    componentWillReceiveProps = props => {
        let state = {...this.state, ...props};

        state.fieldList = state.fields.map(field => {
            return {
                value: field.path,
                label: field.title
            }
        });

        this.setState(state)
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.handleViewResize, false);
    }

    handleViewResize = () => {
        this.setState({
            sortableMaxHeight: window.innerHeight - 70
        });
    }

    handleChangeModalComponent = (event) => {
        let modal = { ...this.state.modal };

        if (event.hasOwnProperty("target")) {
            modal[event.target.name] = event.target.value;
        } else {
            modal[event.name] = event.value;
            modal.fieldName = event.label
        }

        this.setState({
            modal: modal,
            disableSave: !this.isFormValid(modal)
        });
    }

    handleChangeCheckBoxComponent = (event) => {
        let modal = { ...this.state.modal };

        if (event.hasOwnProperty("target")) {
            const property = event.target.name;
            if(property == "isConcatAll" || property == "isConvertNull" )
            {
                modal[property] = event.target.checked;
            }
                
        } else {
            modal[event.name] = event.value;
        }

        this.setState({
            modal: modal,
        });
    }

    isFormValid = (modal) => {
        switch (modal.mode) {
            case "case":
                return modal.fieldInput != null;
            default:
                if (modal.typeInput == "field") {
                    return modal.fieldInput != null;
                } else {
                    return true;
                }
        }
    }

    handleAddCase = (parent, path) => {
        let {
            attributes,
            fieldTransform,
        } = this.state;

        fieldTransform.cases = addNodeUnderParent({
            treeData: fieldTransform.cases,
            parentKey: path ? path[path.length - 1] : null,
            getNodeKey,
            newNode: {
                desField: "case",
                fieldTransformAttributes: [{
                    codAttribute: attributes.field_type,
                    desValue: "field",
                    desLabel: "field_type",
                    indPosition: 1
                }, {
                    codAttribute: attributes.from,
                    desValue: null,
                    desLabel: "from",
                    indPosition: 0
                }, {
                    codAttribute: attributes.structure_in,
                    desValue: "false",
                    desLabel: "structure_in",
                    indPosition: 2
                }, {
                    codAttribute: attributes.field_name,
                    desValue: "",
                    desLabel: "field_name",
                    indPosition: 3
                }],
                children: [],
                expanded: true
            }
        }).treeData;

        this.setState({ fieldTransform: fieldTransform }, this.state.updateEvent);
    }

    handleAddWhen = (parent, path) => {
        let {
            attributes,
            fieldTransform
        } = this.state;

        fieldTransform.cases = addNodeUnderParent({
            treeData: fieldTransform.cases,
            parentKey: path ? path[path.length - 1] : null,
            getNodeKey,
            newNode: {
                desField: "when",
                fieldTransformAttributes: [{
                    codAttribute: attributes.signal,
                    desValue: "equal",
                    desLabel: "signal",
                    indPosition: 0
                }, {
                    codAttribute: attributes.field_type,
                    desValue: "text",
                    desLabel: "field_type",
                    indPosition: 1
                }, {
                    codAttribute: attributes.to,
                    desValue: null,
                    desLabel: "to",
                    indPosition: 2
                }, {
                    codAttribute: attributes.field_name,
                    desValue: "",
                    desLabel: "field_name",
                    indPosition: 3
                }],
                children: [],
                expanded: true
            }
        }).treeData;

        this.setState({ fieldTransform: fieldTransform }, this.state.updateEvent);
    }

    handleAddThen = (path) => {
        let {
            attributes,
            fieldTransform
        } = this.state;

        fieldTransform.cases = addNodeUnderParent({
            treeData: fieldTransform.cases,
            parentKey: path ? path[path.length - 1] : null,
            getNodeKey,
            newNode: {
                desField: "then",
                fieldTransformAttributes: [{
                    codAttribute: attributes.field_type,
                    desValue: "text",
                    desLabel: "field_type",
                    indPosition: 0
                }, {
                    codAttribute: attributes.to,
                    desValue: null,
                    desLabel: "to",
                    indPosition: 1
                }, {
                     codAttribute: attributes.is_concatall,
                     desValue: "false",
                     desLabel: "is_concatall",
                     indPosition: 2
                 }, {
                    codAttribute: attributes.field_name,
                    desValue: "",
                    desLabel: "field_name",
                    indPosition: 3
                },{
                    codAttribute: attributes.is_convertnull,
                    desValue: "false",
                    desLabel: "is_convertnull",
                    indPosition: 4
                }]
            }
        }).treeData;

        this.setState({ fieldTransform: fieldTransform }, this.state.updateEvent);
    }

    handleRemove = (path) => {
        let { fieldTransform } = this.state;

        this.state.onRemove(getNodeAtPath({
            treeData: fieldTransform.cases,
            path: path,
            getNodeKey
        }).node);

        fieldTransform.cases = removeNodeAtPath({
            treeData: fieldTransform.cases,
            path,
            getNodeKey,
        });

        this.setState({ fieldTransform: fieldTransform }, this.state.updateEvent);
    }

    handleOpenModal = (item) => {
        let {
            attributes,
            modal
        } = this.state;

        modal.opened = true;
        modal.item = item;
        modal.mode = item.desField;

        let operatorInput = TransformUtil.getAttributeValueByCode(item.fieldTransformAttributes, attributes.signal);
        if (operatorInput != null) {
            modal.operatorInput = operatorInput;
        } else {
            modal.operatorInput = "equal";
        }

        let fieldType = TransformUtil.getAttributeValueByCode(item.fieldTransformAttributes, attributes.field_type);
        if (fieldType != null) {
            modal.typeInput = fieldType;
        } else {
            modal.typeInput = "text";
        }

        const fieldAttributeCode = attributes[modal.mode == "case" ? "from" : "to"];
        let fieldInput = TransformUtil.getAttributeValueByCode(item.fieldTransformAttributes, fieldAttributeCode);
        if (fieldInput != null) {
            modal[modal.typeInput + "Input"] = fieldInput;
        } else {
            modal.textInput = "";
            modal.fieldInput = null;
        }

        let isConcatAllAttribute = TransformUtil.getAttributeValueByCode(item.fieldTransformAttributes, attributes.is_concatall);
        if (isConcatAllAttribute != null) {
            if(isConcatAllAttribute == 'true')
                modal.isConcatAll = true;
            else if(isConcatAllAttribute == 'false')
                modal.isConcatAll = false;
            else
                modal.isConcatAll = isConcatAllAttribute;               
        } 
        else {
            modal.isConcatAll = false;
        }

        let isConvertNullAttribute = TransformUtil.getAttributeValueByCode(item.fieldTransformAttributes, attributes.is_convertnull);
        if (isConvertNullAttribute != null) {
            if(isConvertNullAttribute == 'true')
                modal.isConvertNull = true;
            else if(isConvertNullAttribute == 'false')
                modal.isConvertNull = false;
            else
                modal.isConvertNull = isConvertNullAttribute;               
        } 
        else {
            modal.isConvertNull = false;
        }

        let fieldName = TransformUtil.getAttributeByCode(item.fieldTransformAttributes, attributes.field_name)
        if( fieldName){
            modal.fieldName = fieldName.desValue
        }

        this.setState({
            modal: modal,
            disableSave: !this.isFormValid(modal)
        });
    }

    handleCloseModal = () => {
        let { modal } = this.state;
        modal.opened = false;

        this.setState({ modal: modal });
    }

    handleSave = () => {
        const {
            attributes,
            modal
        } = this.state;
        const { fieldTransformAttributes,concats } = modal.item;

        let operatorAttribute = TransformUtil.getAttributeByCode(fieldTransformAttributes, attributes.signal);
        if (operatorAttribute) {
            operatorAttribute.desValue = modal.operatorInput;
        }

        let fieldTypeAttribute = TransformUtil.getAttributeByCode(fieldTransformAttributes, attributes.field_type);
        if (fieldTypeAttribute) {
            fieldTypeAttribute.desValue = modal.typeInput;

            const fieldAttributeCode = attributes[modal.mode == "case" ? "from" : "to"];
            let fieldAttribute = TransformUtil.getAttributeByCode(fieldTransformAttributes, fieldAttributeCode);
            if (fieldAttribute) {
                fieldAttribute.desValue = modal[modal.typeInput + "Input"];
            }
        }

        let isConcatAllAttribute = TransformUtil.getAttributeByCode(fieldTransformAttributes, attributes.is_concatall); 
        if(isConcatAllAttribute){
            if(modal.isConcatAll)
                isConcatAllAttribute.desValue = 'true';
            else
                isConcatAllAttribute.desValue = 'false';

        }

        let isConvertNullAttribute = TransformUtil.getAttributeByCode(fieldTransformAttributes, attributes.is_convertnull); 
        if(isConvertNullAttribute){
            if(modal.isConvertNull)
                isConvertNullAttribute.desValue = 'true';
            else
            isConvertNullAttribute.desValue = 'false';

        }

        let fieldName = TransformUtil.getAttributeByCode(fieldTransformAttributes, attributes.field_name)
        if( fieldName){
            fieldName.desValue = modal.fieldName;
        }

        if(modal.typeInput != "concat" && concats){
            concats.forEach(cc => {
                this.removeCaseConcat(cc);
            });
            modal.item.concats = [];
        }
        this.state.updateEvent();
        this.handleCloseModal();
    }

    render() {
        const {
            disableSave,
            fieldTransform,
            modal,
            sortableMaxHeight
        } = this.state;

        return (
            <div style={{height: `500px`}}>
                <StyledSortableTreeTransform 
                    style={{maxHeight: sortableMaxHeight + "px"}} 
                    canDrag={false} 
                    treeData={fieldTransform.cases} 
                    generateNodeProps={({ node, path }) => this.getTreeProps(node, path)}
                    onChange={(cases) => {
                        fieldTransform.cases = cases;

                        this.setState({ fieldTransform: fieldTransform });
                    }} 
                    isVirtualized={false}/>

                <StyledDialog onBackdropClick={this.handleCloseModal} onEscapeKeyDown={this.handleCloseModal} open={modal.opened}>
                    <DialogTitle>{Translate("properties")}</DialogTitle>
                    <DialogContent>
                        {
                            modal.mode == "case" ? (
                                this.renderFieldComponent()
                            ) : (
                                    modal.mode == "when" ? (
                                        this.renderOperatorComponent()
                                    ) : (
                                            this.renderTypeComponent()
                                        )
                                )
                        }
                    </DialogContent>
                    <DialogActions>
                        <StyledButton variant="contained" color="primary" onClick={this.handleCloseModal}>{Translate("cancel")}</StyledButton>
                        <StyledButton variant="outlined" disabled={disableSave} onClick={this.handleSave}>{Translate("save")}</StyledButton>
                    </DialogActions>
                </StyledDialog>
            </div>
        );
    }

    getTreeProps = (item, path) => {
        let buttons = [
            <Tooltip title={Translate("edit")}>
                <StyledIconButton onClick={this.handleOpenModal.bind(this, item)}>
                    <MdEdit size={20}/>
                </StyledIconButton>
            </Tooltip>
        ];

        if (this.canRenderCaseButton(item)) {
            buttons.push(
                <Tooltip title={Translate("add_case")}>
                    <StyledIconButton onClick={this.handleAddCase.bind(this, item, path)}>
                        <MdFilterList size={20} />
                    </StyledIconButton>
                </Tooltip>
            );
        }

        if (this.canRenderWhenButton(item)) {
            buttons.push(
                <Tooltip title={Translate("add_when")}>
                    <StyledIconButton onClick={this.handleAddWhen.bind(this, item, path)}>
                        <MdLowPriority size={20} />
                    </StyledIconButton>
                </Tooltip>
            );
        }

        if (this.canRenderThenButton(item, path)) {
            buttons.push(
                <Tooltip title={Translate("add_then")}>
                    <StyledIconButton onClick={this.handleAddThen.bind(this, path)}>
                        <MdUndo size={20} />
                    </StyledIconButton>
                </Tooltip>
            );
        }

        if (this.canRenderRemoveButton(path)) {
            buttons.push(
                <Tooltip title={Translate("remove")}>
                    <StyledIconButton onClick={this.handleRemove.bind(this, path)}>
                        <MdDelete size={20} />
                    </StyledIconButton>
                </Tooltip>
            );
        }

        let title;

        if (item.desField == "case") {
            title = this.buildCaseTitle(item);
        } else if (item.desField == "when") {
            title = this.buildWhenTitle(item);
        } else {
            title = this.buildThenTitle(item);
        }

        return {
            buttons,
            title
        }
    }

    canRenderCaseButton = (item) => {
        return (item.desField == "when" && item.children.length == 0);
    }

    canRenderWhenButton = (item) => {
        if (item.desField == "case") {
            let childrens = item.children;

            if (childrens.length > 0) {
                let lastChild = item.children[childrens.length - 1];

                if (lastChild.desField == "when") {
                    return true;
                }
            } else {
                return true;
            }
        }

        return false;
    }

    canRenderThenButton = (item) => {
        if (item.desField == "when" && item.children.length == 0) {
            return true;
        } else if (item.desField == "case") {
            let childrens = item.children;

            if (childrens.length > 0) {
                let lastChild = item.children[childrens.length - 1];

                if (lastChild.desField == "when") {
                    return true;
                }
            }
        }

        return false;
    }

    canRenderRemoveButton = (path) => {
        return path.length > 1;
    }

    buildCaseTitle = ({fieldTransformAttributes}) => {
        const { attributes } = this.state;

        let title;
        let fieldPath = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.from);
        let fieldName = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.field_name);

        if (fieldName) {
            title = <div>{Translate("case")} <b>{fieldName}</b></div>;
        }else if (fieldPath){
            title = <div>{Translate("case")} <b>{fieldPath.split(".").pop()}</b></div>;
        } else {
            title = <div>{Translate("case")} <u><i>{Translate("uninformed")}</i></u></div>;
        }

        return title;
    }

    buildWhenTitle = ({fieldTransformAttributes}) => {
        const { attributes } = this.state;

        let title;
        let operatorInput = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.signal);
        let fieldType = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.field_type);
        let textValue = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.to);
        let fieldName = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.field_name);

        if (fieldType == "text") {
            title = <div>{Translate("when")} {Translate(operatorInput)} {textValue == "" || textValue == null ? <u><i>{Translate("empty")}</i></u> : <i>{textValue}</i>}</div>;
        } else if(fieldName){
            title = <div>{Translate("when")} {Translate(operatorInput)} <b>{fieldName}</b></div>;
        } else {
            title = <div>{Translate("when")} {Translate(operatorInput)} <b>{textValue.split(".").pop()}</b></div>;
        }

        return title;
    }

    buildThenTitle = ({fieldTransformAttributes}) => {
        const { attributes } = this.state;

        let title;
        let operatorInput = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.signal);
        let fieldType = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.field_type);
        let textValue = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.to);
        let fieldName = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.field_name);
        if (fieldType == "text") {
            title = <div>{Translate("then")} {textValue == "" || textValue == null ? <u><i>{Translate("empty")}</i></u> : <i>{textValue}</i>}</div>;
        } else if(fieldName){
            title = <div>{Translate("then")} {Translate(operatorInput)} <b>{fieldName}</b></div>;
        } else {
            title = <div>{Translate("then")} <b>{textValue.split(".").pop()}</b></div>;
        }

        return title;
    }

    renderOperatorComponent = () => {
        const {
            operatorList,
            modal
        } = this.state;

        return (
            <div>
                <FormControl style={{ width: "100%", marginBottom: "20px" }}>
                    <ConectorSelect name={"operatorInput"} label={"operator"} placeholder={"choose"} options={operatorList}
                        value={operatorList.find(operator => operator.value == modal.operatorInput)}
                        onChange={this.handleChangeModalComponent.bind(this)} />
                </FormControl>

                {this.renderTypeComponent()}
            </div>
        );
    }

    renderTypeComponent = () => {
        const {
            modal,
            fieldTypeList
        } = this.state;

        return (
            <div>
                <FormControl style={{ width: "100%", marginBottom: "20px" }}>
                    <ConectorSelect name={"typeInput"} label={"type"} placeholder={"choose"} options={fieldTypeList}
                        value={fieldTypeList.find(fieldType => fieldType.value == modal.typeInput)}
                        onChange={this.handleChangeModalComponent.bind(this)} />
                </FormControl>
                {
                    (() => {
                        switch (modal.typeInput) {
                            case "text":
                                return this.renderTextComponent();
                            case "field":
                                return this.renderFieldComponent();
                            case "concat":
                                return this.renderConcatComponent();
                            default:
                                return null;
                        }
                    })()
                }

                {
                    modal.mode != "then" ? "": modal.typeInput == "text" || modal.typeInput == "field" ? this.renderCheckBoxComponent():""
                }

            </div>
        );
    }

    renderTextComponent = () => {
        const { textInput } = this.state.modal;

        return (
            <ConectorInputText label={"string"} name="textInput" value={textInput} onChange={this.handleChangeModalComponent.bind(this)} />
        );
    }

    renderFieldComponent = () => {
        const {
            fieldList,
            modal
        } = this.state;

        return (
            <FormControl style={{ width: "100%" }}>
                <ConectorSelect name={"fieldInput"} label={"field"} placeholder={"choose"} options={fieldList}
                    value={fieldList.find(field => field.value == modal.fieldInput)}
                    onChange={this.handleChangeModalComponent.bind(this)}
                    showTooltip={true}  />
            </FormControl>
        );
    }

    renderConcatComponent = () => {
        const {
            fields,
            attributes,
            modal
        } = this.state;
        if(!modal.item.concats){
            modal.item.concats = []
        }else{
            modal.item.concats = this.getConcatsTransformToEdit(modal.item.concats);
        }
        return (
                <TransformConcat attributes={attributes}  fields={fields} fieldTransform={modal.item} updateEvent={this.updateEvent}
                onRemove={this.removeCaseConcat} />

        );
    }
    updateEvent = (editing) => {
        if(editing != undefined){
            const editando = editing;
            let modal = { ...this.state.modal };
            this.setState({
                modal: modal,
                disableSave: editando
            });
        }
    }

    removeCaseConcat = (fieldTransforms) => {
        this.state.onRemoveCaseConcat(fieldTransforms);
    }
    getConcatsTransformToEdit = (concatSources) => {
        const {
            attributes,
        } = this.state;

        return concatSources.map(concatSource => {
            let fieldType = TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.field_type);
            let originalPath = TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.from);

            return {
                ...concatSource,
                fieldType: fieldType,
                textInput: (fieldType == "text" ? originalPath : ""),
                fieldPath: (fieldType == "text" ? "" : originalPath),
                isSubstring: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.is_substring),
                initPosition: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.ini_substring),
                endPosition: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.end_substring),
                isConcatAll: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.is_concatall),
                isConvertNull: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.is_convertnull)
            };
        });
    }
    renderCheckBoxComponent = () => {
        const {
            modal
        } = this.state;
    
        if(modal.typeInput == "field" )
        {
            return (
                <>
                <FormControlLabel label="Concat All"
                control={
                    <Checkbox checked={modal.isConcatAll} name="isConcatAll" onChange={this.handleChangeCheckBoxComponent.bind(this)} />
                }/>
                <FormControlLabel label="Converter vazio para NULL"
                control={
                    <Checkbox checked={modal.isConvertNull} name="isConvertNull" onChange={this.handleChangeCheckBoxComponent.bind(this)} />
                }/>
                </>
            );
        }
        else{
            return (
                <FormControlLabel label="Converter vazio para NULL"
                control={
                    <Checkbox checked={modal.isConvertNull} name="isConvertNull" onChange={this.handleChangeCheckBoxComponent.bind(this)} />
                }/>
            );
        }
    }
    

}
export default TransformCase;