import { styled } from '@mui/system'
import { Grid2 } from "@mui/material"

const StyledWizardContainer = styled('div')({
  width: '100%',
  height: 'calc(100vh - 157px)'
})

const StyledWizardBody = styled('div')({
  backgroundColor: '#FFFFFF',
  border: '1px solid #B8C6D6',
  borderRadius: '10px',
  padding: '1vh',
  height: '100%'
})

const StyledWizardContentArea = styled(Grid2)({
  backgroundColor: '#F2F2F2',
  border: '1px solid #B8C6D6',
  height: 'calc(100vh - 280px)',
  padding: '1vh',
  overflow: 'auto',
  borderRadius: '10px'
})

const StyledContainerButtonsWizard = styled('div')({
  marginTop: '5px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between'
})

export { StyledWizardBody, StyledWizardContainer, StyledWizardContentArea, StyledContainerButtonsWizard }