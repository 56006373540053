import styled from 'styled-components';


const StyledDate = styled.h1`
  font-size: 10px;
  font-weight: semi-bold;
  color: #6f8296;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0px;
  opacity: 0.6;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export default StyledDate;
