import React, { Component, Fragment } from 'react'
import Session from '../../../utils/session-info'
import Translate from '../../../i18n/translate'
import Observation from '../../../utils/observation-utils.js'
import ConnectionsService from '../connections-service'
import { Tab, Grid2 } from '@mui/material'
import Loading from "../../../componentsUI/loading"
import ConectorInputText from "../../../componentsUI/inputText"
import ConectorInputPassword from "../../../componentsUI/inputPassword"
import ConectorCheckbox from "../../../componentsUI/checkBox"
import { StyledTabs } from "../../../componentsUI/styledComponents/styledTabs"
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import ConnectionHeaderParams from "../connection-header-params"

class Step2 extends Component {
    constructor(props) {
        super(props);

        const { attributeTypes, connection, refresh } = props;

        this.state = {
            attributeTypes,
            connection: {
                ...connection
            },
            refresh,
            ready: false,
            selectedTab: 0,
            paramsHeaderTab: false,
            tabs: [
                {
                    id: "main",
                    label: "Parâmetros Gerais",
                    indPosition: 0
                },
                {
                    id: "aux",
                    label: "Parâmetros Header",
                    indPosition: 1
                }
            ]
        }
    }

    componentWillMount = _ => {
        this.getAttributesByCodConnectionMode()
    }

    getAttributesByCodConnectionMode = () => {
        let connection = { ...this.state.connection };

        ConnectionsService
            .GetAttributesByCodConnectionMode(connection.codConnectionMode)
            .then((attributes) => {
                let state = { ...this.state };
                state.ready = true;
                state.attributeFields = attributes;
                state.paramsHeaderTab = this.enableParamsHeaderTab(connection.codConnectionMode)

                if (!connection.attributes) {
                    connection.attributes = [];
                }

                attributes.forEach(attr => {
                    if (!connection.attributes.find(att => att.codAttribute === attr.codAttribute)) {
                        connection.attributes.push({
                            isRequired: attr.isRequired,
                            codAttribute: attr.codAttribute,
                            codInstance: Session().codInstance
                        })
                    }
                });

                state.connection = connection;

                this.setState(state, () => {
                    state.stepDisabled = this.stepDisabled();
                    this.props.refresh(state);
                });
            })
    }

    enableParamsHeaderTab = codConnectionMode => {
        //21 Rest Oauth2
        return codConnectionMode == 21 ? true : false
    }

    stepDisabled = () => {
        const { attributes } = this.state.connection;
        for (let i = 0; i < attributes.length; i++) {
            const attribute = attributes[i];
            if (attribute.isRequired === "true") {
                if (!attribute.value && attribute.value !== 0) {
                    return true;
                }
            }
        }

        return false;
    }

    handleChange = e => {
        let state = { ...this.state };
        let desValue;

        if (e.target.type === "checkbox") {
            desValue = e.target.checked ? "true" : "false";
        } else if (e.type === "blur") {
            desValue = e.target.value.trim();
        } else {
            desValue = e.target.value;
        }

        const attribute = state.connection.attributes.find(attr =>
            attr.codAttribute == e.target.name
        );

        if (attribute) {
            attribute.value = desValue;
        }

        state.stepDisabled = this.stepDisabled();

        this.setState(state, () => {
            this.props.refresh(state);
        });
    }

    getValue = codAttribute => {
        const { connection } = this.state;
        codAttribute = parseInt(codAttribute, 10);

        const attr = connection.attributes.find(attr =>
            attr.codAttribute === codAttribute
        );

        if (attr && attr.value) {
            return attr.value;
        } else {
            return '';
        }

    }

    renderAttributes = () => {
        const { attributeTypes, attributeFields, connection } = { ...this.state };

        return attributeFields.map(field => {
            const type = attributeTypes.find(type =>
                type.codAttributeType === field.codAttributeType
            );

            const translatedLabel = Translate(field.label);
            const obs = Observation(field.codAttribute);

            // Encontra o atributo correspondente
            const attribute = connection.attributes.find(
                attr => attr.codAttribute === field.codAttribute
            );

            // Garante que `attribute` exista antes de acessar `.value`
            const value = attribute ? attribute.value : "";

            switch (type.desAttributeType) {
                case "boolean":
                    return (
                        <Grid2 size={4} key={field.codAttribute}>
                            <ConectorCheckbox
                                name={String(field.codAttribute)}
                                color="default"
                                value={value} // Use o valor do atributo encontrado
                                onChange={this.handleChange.bind(this)}
                                checked={value === "true"} // Converte para booleano
                                label={translatedLabel}
                            />
                        </Grid2>
                    );
                case "password":
                    return (
                        <Grid2 size={4} key={field.codAttribute}>
                            <ConectorInputPassword
                                label={translatedLabel}
                                help={`${Translate("enter_field")} ${translatedLabel}`}
                                name={String(field.codAttribute)}
                                value={value}
                                required={field.isRequired === "true"}
                                type={false}
                                onChange={this.handleChange.bind(this)}
                            />
                        </Grid2>
                    );
                default:
                    return (
                        <Grid2 size={4} key={field.codAttribute}>
                            <ConectorInputText
                                label={translatedLabel}
                                help={`${Translate("enter_field")} ${translatedLabel} ${obs}`}
                                name={String(field.codAttribute)}
                                value={value}
                                required={field.isRequired === "true"}
                                onChange={this.handleChange.bind(this)}
                                onBlur={this.handleChange.bind(this)}/>
                        </Grid2>
                    );
            }
        })
    }

    renderTabs = () => {
        const { tabs } = this.state;

        return tabs.map(tab => (<Tab key={tab.id} label={tab.label} />))
    }

    changeTab = (_, selectedTab) => this.setState({ selectedTab })

    getHeaderParams = () => {
        const { connection } = this.state

        return connection.connectionsHeaderParameters == undefined ? [] : connection.connectionsHeaderParameters
    }

    getDeletedHeaderParams = () => {
        const { connection } = this.state

        return connection.deletedHeaderParams == undefined ? [] : connection.deletedHeaderParams
    }

    updateConnectionHeaderParams = (headerParams, deletedHeaderParams) => {
        const { connection } = this.state

        connection.connectionsHeaderParameters = headerParams
        connection.deletedHeaderParams = deletedHeaderParams

        this.setState({connection})
    }

    refresh = () => {
        let state = { ...this.state }

        this.props.refresh(state)
    }

    render() {
        const { ready, selectedTab, paramsHeaderTab, connection } = this.state

        if (!ready) return <Loading/>

        return (
            <Fragment>
                <StyledTabs
                    enable={paramsHeaderTab}
                    value={selectedTab}
                    onChange={this.changeTab.bind(this)}
                    variant="scrollable"
                    scrollButtons="auto">
                    {this.renderTabs()}
                </StyledTabs>
                <StyledPaper className={'bg_darken_04'}>
                    {selectedTab == 0 ? <Grid2 container spacing={1}>{this.renderAttributes()}</Grid2> 
                    : <ConnectionHeaderParams 
                        headerParams={this.getHeaderParams()}
                        deletedHeaderParams={this.getDeletedHeaderParams()}
                        updateConnectionHeaderParams={this.updateConnectionHeaderParams.bind(this)}/>}
                </StyledPaper>
            </Fragment>
        )
    }
}

export default Step2